import React, { useState, useEffect } from 'react';
import { TrendingUp, ArrowUpRight, ChevronLeft, ChevronRight } from 'lucide-react';
import axiosInstance from '../../axiosInstance';

const StockDashboardCarousel = () => {
  const [stockData, setStockData] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentDayIndex, setCurrentDayIndex] = useState(0);

  const days = ["monday", "tuesday", "wednesday", "thursday", "friday"];
  const stock_types = ["Entery", "Closing"];

  const fetchAllStockData = async () => {
    try {
      const fetchPromises = days.flatMap(day => 
        stock_types.map(async (type) => {
          try {
            const response = await axiosInstance.get('/sotd/stocks_history', {
              params: { stock_day: day, stock_type: type }
            });
            return { 
              day, 
              type, 
              stocks: response.data.data.stock_data.dayTrading 
            };
          } catch (err) {
            return { day, type, stocks: [] };
          }
        })
      );

      const results = await Promise.all(fetchPromises);
      const dataMap = results.reduce((acc, { day, type, stocks }) => {
        if (!acc[day]) acc[day] = {};
        acc[day][type] = stocks;
        return acc;
      }, {});

      setStockData(dataMap);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllStockData();
    
    const intervalId = setInterval(() => {
      setCurrentDayIndex((prevIndex) => 
        (prevIndex + 1) % days.length
      );
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const handlePrevDay = () => {
    setCurrentDayIndex((prevIndex) => 
      prevIndex === 0 ? days.length - 1 : prevIndex - 1
    );
  };

  const handleNextDay = () => {
    setCurrentDayIndex((prevIndex) => 
      (prevIndex + 1) % days.length
    );
  };

  const renderStockCard = (stock, type) => (
    <div 
      key={stock.ticker} 
      className="bg-[#2A2A2A] rounded-lg p-4 shadow-lg transform transition-transform hover:scale-105 space-y-3"
    >
      <div className="flex justify-between items-center">
        <span className="text-xl font-semibold text-white truncate">{stock.name}</span>
        <span className="text-sm text-blue-400 bg-blue-900/50 px-2 py-1 rounded-full">
          {stock.ticker}
        </span>
      </div>
      
      <div className="grid grid-cols-2 gap-2 text-gray-300">
        <div>
          <p className="text-xs opacity-70">Price</p>
          <p className="text-green-400 font-bold">${stock.price}</p>
        </div>
        <div>
          <p className="text-xs opacity-70">{type === 'Entery' ? 'Entry' : 'Exit'} Point</p>
          <p className="text-blue-400 font-bold">${type === 'Entery' ? stock.entry_point : stock.exit_point}</p>
        </div>
        <div>
          <p className="text-xs opacity-70">Change</p>
          <p className={`font-bold ${stock.change_percent > 0 ? 'text-green-400' : 'text-red-400'}`}>
            {stock.change_percent.toFixed(2)}%
            <ArrowUpRight className="inline ml-1" size={14} />
          </p>
        </div>
      </div>
    </div>
  );

  if (loading) return (
    <div className="flex justify-center items-center h-[500px] bg-[#1E1E1E]">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-blue-500"></div>
    </div>
  );

  return (
    <section className=" flex flex-col py-8 px-4">
      <h2 className="text-3xl font-bold text-white text-center mb-6">
        Stock Data Overview
      </h2>
      
      <div className="container mx-auto px-4 py-6 flex-grow relative">
        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10">
          <button 
            onClick={handlePrevDay}
            className="bg-[#2A2A2A] text-white p-2 rounded-full shadow-lg hover:bg-[#3A3A3A] transition-colors"
          >
            <ChevronLeft size={24} />
          </button>
        </div>
        <div className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10">
          <button 
            onClick={handleNextDay}
            className="bg-[#2A2A2A] text-white p-2 rounded-full shadow-lg hover:bg-[#3A3A3A] transition-colors"
          >
            <ChevronRight size={24} />
          </button>
        </div>

        <div className="relative overflow-hidden h-full">
          <div 
            className="flex transition-transform duration-500 ease-in-out h-full" 
            style={{ transform: `translateX(-${currentDayIndex * 100}%)` }}
          >
            {days.map((day) => (
              <div key={day} className="w-full flex-shrink-0 h-full">
                <div className="flex flex-col h-full">
                  <h2 className="text-2xl font-bold text-white text-center capitalize mb-4">
                    {day}
                  </h2>
                  
                  <div className="grid grid-cols-2 gap-6 flex-grow">
                    <div className="bg-[#2A2A2A]/50 rounded-lg p-4 overflow-auto">
                      <h3 className="text-lg font-semibold text-gray-200 mb-4">Entry Stocks</h3>
                      <div className="grid grid-cols-2 gap-4">
                        {stockData[day]?.['Entery']?.length > 0 
                          ? stockData[day]['Entery'].map(stock => renderStockCard(stock, 'Entery'))
                          : <p className="text-gray-500 text-center">No entry stocks</p>
                        }
                      </div>
                    </div>
                    
                    <div className="bg-[#2A2A2A]/50 rounded-lg p-4 overflow-auto">
                      <h3 className="text-lg font-semibold text-gray-200 mb-4">Closing Stocks</h3>
                      <div className="grid grid-cols-2 gap-4">
                        {stockData[day]?.['Closing']?.length > 0 
                          ? stockData[day]['Closing'].map(stock => renderStockCard(stock, 'Closing'))
                          : <p className="text-gray-500 text-center">No closing stocks</p>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StockDashboardCarousel;
