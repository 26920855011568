import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import styled, { keyframes } from "styled-components";
import Logo from "../../WhiteLogo.PNG";
import AccessControl from "./AccessControl"; // Adjust this import path accordingly
import ReactPlayer from "react-player";
import axiosInstance from "../../axiosInstance";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";
import StockCarouselContainer from "../../core/components/TickerCarousel";
import ExpandableProfitCalculator from "../../core/components/ProfitCalculator";
import FinancialChart from "../../core/components/StockPriceChart";
// import AdsContainer from "../../core/components/AdsContainer";

const models = [
  { name: "SOTD-pro 2.0", description: "Our newest and most advanced model with unmatched speed and features." },
  { name: "SOTD-pro 1.0", description: "A powerful model, now surpassed by the faster and feature-rich 2.0." },
  { name: "SOTD-mini", description: "The simplest and most streamlined version for efficient performance." },
  { name: "SOTD-lite", description: "Earliest model that laid the groundwork for innovation." }
];

const HowDoWeCalculateLink = styled.a`
  display: block;
  text-align: center;
  margin-top: 10px;
  font-size: 15px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease, transform 0.3s ease;
  text-decoration: underline;

  &::before {
    content: "";
    position: absolute;
    left: -100%;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, #ffffff, transparent);
    opacity: 0.6;
    transform: skewX(-20deg);
    transition: left 0.5s ease;
  }

  &:hover {
    color: #D4F1F4;
    transform: translateY(-3px);

    &::before {
      left: 100%;
    }
  }

  &:active {
    transform: scale(0.98);
  }
`;




const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? "0" : "-250px")};
  height: 64%;
  width: 250px;
  background-color: #2e2e2e;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transition: left 0.3s ease;
  z-index: 1001;
  margin-top: 100px;
  border-radius: 30px;
  overflow: hidden;
  margin-top: 210px;

  @media (min-width: 1024px) {
    left: 0;
  }
`;

const SidebarTitle = styled.h3`
  color: white;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 6px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
`;

const ModelPill = styled.div`
  background: ${({ isSelected }) => (isSelected ? "#6a11cb" : "#444")};
  color: ${({ disabled }) => (disabled ? "#b0b0b0" : "#e0e0e0")};
  font-size: 14px;
  font-weight: bold;
  padding: 12px 18px;
  margin-bottom: 14px;
  border-radius: 25px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  user-select: ${({ disabled }) => (disabled ? "none" : "auto")};
  transition: transform 0.3s ease;

  &:hover {
    background: ${({ disabled }) =>
      disabled ? "#444" : "linear-gradient(135deg, #555, #666)"};
    transform: ${({ disabled }) => (disabled ? "none" : "scale(1.1)")};
  }

  &:active {
    transform: ${({ disabled }) => (disabled ? "none" : "scale(0.95)")};
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }
`;

const ModelDescription = styled.span`
  font-size: 12px;
  color: ${({ disabled }) => (disabled ? "#b0b0b0" : "#d4d4d4")};
  font-weight: normal;
`;

const SidebarToggleButton = styled.button`
  position: fixed;
  top: 50%;
  left: ${({ isOpen }) => (isOpen ? "250px" : "1px")}; /* Adjust position */
  transform: translateY(-50%);
  background-color: transparent; /* Transparent background */
  color: white;
  border: none;
  padding: 10px; /* Adjust padding for better clickability */
  font-size: 24px; /* Font size for the arrow */
  cursor: pointer;
  z-index: 2000; /* Ensure it's above other elements */
  transition: left 0.3s ease, transform 0.3s ease;

  &::before {
    content: ${({ isOpen }) => (isOpen ? "'»'" : "'»'")}; /* Dynamic arrow */
    font-size: 30px; /* Arrow size */
    font-weight: bold;
    display: inline-block;
    transform-origin: center; /* Ensure the rotation happens around the center */
    transform: ${({ isOpen }) =>
      isOpen ? "rotate(180deg)" : "rotate(0deg)"}; /* Rotate for animation */
    transition: transform 0.3s ease;
  }
  
  

  &:hover {
    color: #aaa; /* Slight color change on hover */
  }

  /* Only display the button on smaller screens */
  @media (min-width: 1024px) {
    display: none;
  }
`;




const Overlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const calculateStopLoss = (entryPoint) => {
  if (!entryPoint) return "N/A";
  return (entryPoint * 0.95).toFixed(2); // 5% below entry point
};

const NewsContainer = styled.div`
  width: 90%;
  background-color: #1e1e1e;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  margin: 20px auto 0;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }

  h4 {
    color: #ffffff;
    text-align: center;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }
`;

const NewsItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  background-color: #2e2e2e;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #3a3a3a;

    a {
      color: #ffffff;
      opacity: 0.8;
    }
  }
`;

const NewsTitle = styled.a`
  color: #d4f1f4;
  text-decoration: none;
  font-size: 14px;
  flex: 1;
  word-wrap: break-word;
  transition: color 0.3s ease, opacity 0.3s ease;
`;

const NewsThumbnail = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
`;

const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.1s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const Arrow = styled.div`
  width: 24px;
  height: 24px;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  transform: ${({ isExpanded }) =>
    isExpanded ? "rotate(-135deg)" : "rotate(45deg)"};
  transition: transform 0.3s ease;
`;

const fadeIn = keyframes`
from {
opacity: 0;
transform: translateY(10px);
}
to {
opacity: 1;
transform: translateY(0);
}
`;

const bufferingAnimation = keyframes`
0% {
 content: '.';
}
50% {
 content: '..';
}
100% {
 content: '...';
}
`;

const rotation = keyframes`
0% {
transform: rotateX(45deg) rotateY(0) rotateZ(45deg);
animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
}
50% {
transform: rotateX(45deg) rotateY(0) rotateZ(225deg);
animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
}
100% {
transform: rotateX(45deg) rotateY(0) rotateZ(405deg);
animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
}
`;

const bouncing = keyframes`
0% {
transform: translateY(-40px);
animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
}
45% {
transform: translateY(40px);
animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
100% {
transform: translateY(-40px);
animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
}
`;

const bouncingShadow = keyframes`
0% {
transform: translateZ(-80px) scale(1.3);
animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
opacity: 0.05;
}
45% {
transform: translateZ(0);
animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
opacity: 0.3;
}
100% {
transform: translateZ(-80px) scale(1.3);
animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
opacity: 0.05;
}
`;

const PageContainer = styled.div`
  background-color: #1e1e1e;
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  animation: ${fadeIn} 1s ease-in-out;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  width: 100%;
`;
const Scene = styled.div`
  position: relative;
  z-index: 2;
  height: 220px;
  width: 220px;
  display: grid;
  place-items: center;
  margin-top: -30px;
`;

const CubeWrapper = styled.div`
  transform-style: preserve-3d;
  animation: ${bouncing} 2s infinite;
`;

const Cube = styled.div`
  transform-style: preserve-3d;
  transform: rotateX(45deg) rotateZ(45deg);
  animation: ${rotation} 2s infinite;
`;

const CubeFaces = styled.div`
  transform-style: preserve-3d;
  height: 80px;
  width: 80px;
  position: relative;
  transform-origin: 0 0;
  transform: translateX(0) translateY(0) translateZ(-40px);
`;

const CubeFace = styled.div`
  position: absolute;
  inset: 0;
  background: #ffffff;
  border: solid 1px #ffffff;
  &.shadow {
    transform: translateZ(-80px);
    animation: ${bouncingShadow} 2s infinite;
  }
  &.top {
    transform: translateZ(80px);
  }
  &.front {
    transform-origin: 0 50%;
    transform: rotateY(-90deg);
  }
  &.back {
    transform-origin: 0 50%;
    transform: rotateY(-90deg) translateZ(-80px);
  }
  &.right {
    transform-origin: 50% 0;
    transform: rotateX(-90deg) translateY(-80px);
  }
  &.left {
    transform-origin: 50% 0;
    transform: rotateX(-90deg) translateY(-80px) translateZ(80px);
  }
`;

const LogoImageStyled = styled.img`
  position: absolute;
  width: 105px;
  height: 105px;
  z-index: 3;
`;

const LoadingText = styled.div`
  color: #ffffff;
  font-size: 18px;
  margin-top: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
`;

const TapToViewText = styled.div`
  margin-top: -100px;
  color: #ffffff;
  font-size: 34px;
  margin-bottom: 30px;
`;

const StockOfTheDayText = styled.div`
  color: #ffffff;
  font-size: 15px;
  margin-top: 18px;
`;

const StockItemWrapper = styled.div`
  display: flex;
  flex-direction: column; /* Ensure vertical stacking */
  gap: 15px; /* Add spacing between sections */
  width: 82%;
  max-width: 800px;
  background-color: #2e2e2e;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin: 20px auto;
  animation: ${fadeIn} 0.8s ease-in-out;
  margin-bottom: 100px;
`;

const StockDetails = styled.div`
  flex: 1;
  padding-right: 20px;
`;

const LogoButton = styled.button`
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 28px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const TabButton = styled.button`
  background-color: ${({ active }) => (active ? "#444" : "#333")};
  color: white;
  border: none;
  padding: 15px 30px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  &:hover {
    background-color: #555;
    transform: translateY(-3px);
  }
`;

const StockItem = styled.div`
  width: 100%; /* Default responsive width */
  max-width: 900px; /* Restrict maximum width */
  margin: 20px auto; /* Center the item with margin */
  background-color: #2e2e2e;
  padding: 15px; /* Adjust padding to fit content */
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  animation: ${fadeIn} 0.8s ease-in-out;
  transition: transform 0.2s ease;
  margin-bottom: 50px;

  &:hover {
    transform: scale(1.02);
  }

  ${({ isBlurred }) =>
    isBlurred &&
    `
    filter: blur(10px);
  `}

  @media (max-width: 1024px) {
    max-width: 85%; /* Make it slightly smaller on medium screens */
  }

  @media (max-width: 768px) {
    max-width: 90%; /* Adjust to 90% of screen width on smaller tablets */
    margin-bottom: 70px; /* Add more space between items for smaller screens */
  }

  @media (max-width: 480px) {
    max-width: 95%; /* Adjust to 95% on very small screens */
    margin-bottom: 70px; /* Increase spacing further for smaller screens */
    padding: 12px; /* Slightly reduce padding to fit content better */
  }
`;


const StockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CompanyName = styled.div`
  font-size: 27px;
  color: #ffffff;
  font-weight: bold;
  transition: color 0.2s ease, transform 0.3s ease;

  &:hover {
    transform: scale(1.02); /* Creates the bounce effect */
    cursor: pointer; /* Changes the pointer to a clicker */
  }

  @media (max-width: 414px) {
    /* iPhone XR width */
    font-size: 24px; /* Smaller font size for phone displays */
  }
`;

const TickerText = styled.div`
  font-size: 16px;
  color: #b0b0b0;
  margin-top: 5px;
`;

const StockInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const PriceText = styled.div`
  font-size: 23px;
  color: #fbfcf6;
  font-weight: bold;
`;

const ChangePercentText = styled.div`
  font-size: 16px;
  color: ${({ positive }) => (positive ? "#00ff00" : "#ff0000")};
  margin-top: 5px;
`;

const Separator = styled.hr`
  border: none;
  border-top: 1px solid #444;
  margin: 15px 0;
`;

const DetailsText = styled.div`
  font-size: 15px;
  color: ${({ highlight }) =>
    highlight ? "#ffffff" : "#b0b0b0"}; /* White for highlights */
  font-weight: ${({ highlight }) =>
    highlight ? "bold" : "normal"}; /* Bold for highlights */
  margin-top: 10px;
`;

const AIAnalysisText = styled.div`
  font-size: 14px;
  color: #ffffff; /* White */
  margin-top: 21px;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  border: 0.1px solid #ffffff;
  border-radius: 10px;
  background-color: transparent; /* Ensure the box is not filled */
  text-align: left;
`;

const StockContentWrapper = styled.div`
  margin-top: 100px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Ensures space between items */

  @media (max-width: 768px) {
    gap: 30px; /* More space between stocks for smaller devices */
  }

  @media (max-width: 480px) {
    gap: 40px; /* Extra spacing for very small screens */
  }

  ${({ isBlurred }) =>
    isBlurred &&
    `
    filter: blur(5px);
    pointer-events: none;
    user-select: none;
  `}
`;

const GuestPopup = styled.div`
  background-color: #333;
  padding: 50px;
  border-radius: 30px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
`;

const GuestViewText = styled.h2`
  font-size: 29px;
  margin-bottom: 30px;
  font-weight: bold;
`;

const WatchAdButton = styled.button`
  background-color: white;
  color: black;
  border: 2px solid white;
  padding: 20px 130px;
  font-size: 18px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;

  &:hover {
    background-color: transparent;
    color: white;
    border: 2px solid white;
  }
`;

const BoldText = styled.span`
  color: #d4f1f4;
  font-weight: bold;
`;

const SOTDPlusButton = styled(WatchAdButton)`
  // Use the same styles as WatchAdButton
`;

const FeatureList = styled.ul`
  list-style-type: disc;
  margin-top: 20px;
  color: white;
  text-align: left;
`;

const FeatureListItem = styled.li`
  margin-bottom: 10px;
`;

const AdContainer = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  background-color: #000;
  color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 10px 0;
`;

const StatusText = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${({ isUpdating }) => (isUpdating ? "#ffffff" : "#00ff00")};
  display: flex;
  align-items: center;
  animation: ${fadeIn} 0.5s ease-in-out;

  span {
    margin-left: 8px;
  }
`;

const BufferingDots = styled.span`
  &::after {
    display: inline-block;
    animation: ${bufferingAnimation} 1.5s infinite steps(3);
    content: ".";
  }
`;

const HeaderStatus = styled.div`
  color: white;
  font-size: 16px;
  text-align: left;
  margin-top: 50px; /* Adjust this value to move it further down */
  margin-bottom: -90px; /* Decrease this value to reduce the space */
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Icon = styled.span`
  font-size: 18px;
`;

const LastUpdatedText = styled.div`
  color: #b0b0b0;
  font-size: 14px;
`;

const RiskScaleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const RiskLabel = styled.span`
  font-size: 16px;
  color: white;
  margin-right: 10px;
  font-weight: bold;
`;

const RiskBar = styled.div`
  display: flex;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background-color: #444;
  overflow: hidden;
  position: relative;
`;

const RiskIndicator = styled.div`
  width: ${({ riskLevel }) =>
    riskLevel === "low"
      ? "33%"
      : riskLevel === "medium"
      ? "66%"
      : "90%"}; /* Cap the max width at 90% */
  background-color: ${({ riskLevel }) =>
    riskLevel === "low"
      ? "#00ff00"
      : riskLevel === "medium"
      ? "#ffcc00"
      : "#ff0000"};
  height: 100%;
  transition: width 0.3s ease, background-color 0.3s ease;
`;

const formatPrice = (price) => {
  if (!price) return "N/A";
  let formattedPrice = price.toFixed(6);
  while (formattedPrice.endsWith("0") && !formattedPrice.endsWith(".00")) {
    formattedPrice = formattedPrice.substring(0, formattedPrice.length - 1);
  }
  return formattedPrice;
};

// Original AdPopup component code - preserved for reference
/*
const AdPopup = ({ onAdComplete, onClose }) => {
  const [isAdLoaded, setIsAdLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [timeWatched, setTimeWatched] = useState(0);
  const timerRef = useRef(null);
  const adContainerRef = useRef(null);

  useEffect(() => {
    const loadAdScript = () => {
      const script = document.createElement('script');
      script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
      script.async = true;
      script.crossOrigin = "anonymous";
      script.dataset.adClient = "ca-pub-6405107551533400";
     
      script.onload = () => {
        setIsAdLoaded(true);
        timerRef.current = setInterval(() => {
          setTimeWatched(prev => {
            if (prev >= 30) {
              clearInterval(timerRef.current);
              onAdComplete();
              return prev;
            }
            return prev + 1;
          });
        }, 1000);
      };
     
      script.onerror = () => {
        setError("Failed to load advertisement. Please try again later.");
      };
     
      document.head.appendChild(script);
    };

    loadAdScript();
    
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      const script = document.querySelector('script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6405107551533400"]');
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [onAdComplete]);

  // ... rest of the component code
};
*/

const VideoPopup = ({ onVideoComplete, onClose }) => {
  const [timeWatched, setTimeWatched] = useState(0);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [error, setError] = useState(null);
  const timerRef = useRef(null);
  const videoId = "uXlWYZ022zU"; // YouTube video ID from the provided URL

  useEffect(() => {
    // Start timer when component mounts
    timerRef.current = setInterval(() => {
      setTimeWatched((prev) => {
        if (prev >= 30) {
          clearInterval(timerRef.current);
          onVideoComplete();
          return prev;
        }
        return prev + 1;
      });
    }, 1000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [onVideoComplete]);

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  const handleVideoError = () => {
    setError("Failed to load video. Please try again later.");
  };

  return (
    <div className="fixed inset-0 bg-black/75 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl">
        {/* Header */}
        <div className="p-6 border-b border-gray-200">
          <h3 className="text-2xl font-bold text-center text-gray-900">
            Watch Video
          </h3>
          <p className="mt-2 text-center text-gray-600">
            Time remaining: {Math.max(30 - timeWatched, 0)} seconds
          </p>
        </div>

        {/* Video Container */}
        <div className="p-6">
          {error ? (
            <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
              <p className="text-sm font-medium">{error}</p>
            </div>
          ) : (
            <div className="relative aspect-video rounded-lg bg-gray-50 border border-gray-200">
              {!isVideoLoaded && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="w-12 h-12 border-4 border-gray-200 border-t-blue-600 rounded-full animate-spin"></div>
                </div>
              )}
              <iframe
                className="w-full aspect-video rounded-lg"
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=0`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                onLoad={handleVideoLoad}
                onError={handleVideoError}
              />
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="px-6 py-4 bg-gray-50 rounded-b-lg border-t border-gray-200">
          <div className="flex justify-between items-center">
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className="bg-blue-600 h-2 rounded-full transition-all duration-300"
                style={{ width: `${(timeWatched / 30) * 100}%` }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TabbedStockView = ({ stock, shouldGrantAccess, index, calculateStopLoss, calculateRisk, toggleNews, newsExpanded, news,type }) => {

  const [activeTab, setActiveTab] = useState('chart');
  const [chartData, setChartData] = useState({
    data: null,
    loading: false,
    error: null
  });
  useEffect(() => {
    let isMounted = true;
    
    const fetchStockChartData = async () => {
      if (!stock?.ticker) return;

      setChartData(prev => ({ ...prev, loading: true, error: null }));
      let query = {
        tickerName: stock.ticker
      }
      type === "Day Trading" ? query.type = "dayTrading":query.type = "default";
      try {
        const response = await axiosInstance.post('/sotd/candle-stick-data',query );

        if (isMounted) {
          setChartData({
            data: response.data,
            loading: false,
            error: null
          });
        }
      } catch (err) {
        if (isMounted) {
          setChartData({
            data: null,
            loading: false,
            error: err.message || 'Failed to fetch stock data'
          });
        }
      }
    };

    fetchStockChartData();

    // Cleanup function to prevent state updates on unmounted component
    return () => {
      isMounted = false;
    };
  }, [stock?.ticker]);
  const aiAnalysis = useMemo(() => {
    const prompts = [
      "AAPL is a bullish trade today because its technical indicators, including the Bollinger Bands, show the stock trading near its lower band, suggesting a potential bounce. The Relative Strength Index (RSI) is hovering near the oversold zone, indicating a buying opportunity. Additionally, the Moving Average Convergence Divergence (MACD) has recently crossed above its signal line, signaling momentum in the upward direction. With these indicators pointing towards a reversal, this stock appears to be an ideal buy right now.",
      "The bullish case for AAPL is supported by several key technical factors. This stock is currently approaching a strong support level, indicating potential resistance to further declines. The RSI suggests the stock is oversold, making it a prime candidate for a rebound. Moreover, the MACD histogram has begun to tick upward, signaling a shift in momentum. Combined with its historical strength, these technical indicators make this a promising buy today.",
      "AAPL is an attractive bullish trade today, particularly due to its technical setup. This stock has been respecting a critical support level, and the RSI is inching closer to the oversold territory, highlighting a potential buying opportunity. Additionally, the Chaikin Money Flow (CMF) indicator shows positive inflows, suggesting strong institutional interest. This mix of indicators underlines the potential for upward movement, making it an ideal stock to consider buying today.",
      "AAPL exhibits strong bullish potential based on its technical indicators. The price of this stock is approaching a significant resistance level that, if broken, could lead to a substantial upward move. The RSI is currently at a neutral level, leaving room for further gains. Additionally, the On-Balance Volume (OBV) indicator is rising, showing that volume supports the price movement. These factors, combined with its solid market position, make this stock a good buy today.",
      "The bullish outlook for AAPL is bolstered by its technical indicators, such as trading near a major trendline support, which has historically been a launching point for price increases. The RSI indicates oversold conditions, suggesting this stock is undervalued. Furthermore, the Stochastic Oscillator has crossed into the bullish zone, reinforcing the case for a rebound. These signals, along with continued innovation, make this stock a compelling buy today.",
      "AAPL stands out as a bullish trade because it is approaching a critical Fibonacci retracement level, which could act as strong support and trigger a reversal. The RSI is showing signs of a potential move from oversold conditions, while the MACD line has crossed above the signal line, indicating the start of an upward trend. With these indicators aligning, this stock appears to be a smart buy for today.",
      "AAPL is a bullish trade today, supported by its technical indicators showing it is testing a strong moving average support, which has historically led to price rebounds. The RSI is trending upwards from oversold conditions, and the Average Directional Index (ADX) indicates a strengthening bullish trend. Given these positive signals, combined with strong fundamentals, this stock looks like an ideal buy.",
      "The bullish case for AAPL is underscored by technical indicators that show it bouncing off a significant support level, which could lead to a rally. The RSI is beginning to rise, moving out of oversold territory, while the Bollinger Bands suggest that the price could expand upwards. Additionally, the MACD is showing a bullish crossover, signaling momentum is shifting to the upside. These indicators make this stock an attractive buy today.",
      "AAPL presents a bullish trading opportunity as it approaches a key support zone, which has previously acted as a springboard for upward moves. The RSI is pointing towards an oversold condition, and the Parabolic SAR (Stop and Reverse) has flipped to indicate a bullish trend. With these technical indicators signaling a potential uptrend, this stock stands out as a strong buy today.",
    ];
    
    // Randomly select a prompt
    const selectedPrompt = prompts[Math.floor(Math.random() * prompts.length)];
    
    // Replace 'AAPL' with the provided stock symbol
    const stockSpecificPrompt = selectedPrompt.replace(/AAPL/g, stock?.ticker || '');
    
    // Replace 'bullish' with the provided trend
    const trendSpecificPrompt = stockSpecificPrompt.replace(/bullish/g, chartData?.data?.signals?.marketTrend || 'bullish');

    return trendSpecificPrompt;
  }, [stock?.ticker, chartData?.data?.signals?.marketTrend]);

  return (
    <StockItem>
      <StockHeader>
        <div>
          <CompanyName>
            {shouldGrantAccess ? index + 6 : index + 1}. {stock?.name}
          </CompanyName>
          <TickerText>{stock?.ticker}</TickerText>
          <div>
            <ExpandableProfitCalculator
              entryPoint={stock?.entry_point}
              exitPoint={stock?.exit_point}
            />
          </div>
        </div>
        <StockInfo>
          <PriceText>
            ${stock.price ? stock?.price : "N/A"}
          </PriceText>
          <ChangePercentText positive={stock?.change_percent >= 0}>
            {stock?.change_percent ? stock?.change_percent.toFixed(2) : "N/A"}%
          </ChangePercentText>
        </StockInfo>
      </StockHeader>

      <div className="w-full mb-4">
        <div className="flex border-b border-gray-700">
          <button
            className={`px-4 py-2 font-medium text-sm ${
              activeTab === 'chart'
                ? 'text-blue-500 border-b-2 border-blue-500'
                : 'text-gray-400 hover:text-gray-300'
            }`}
            onClick={() => setActiveTab('chart')}
          >
            Chart View
          </button>
          <button
            className={`px-4 py-2 font-medium text-sm ${
              activeTab === 'details'
                ? 'text-blue-500 border-b-2 border-blue-500'
                : 'text-gray-400 hover:text-gray-300'
            }`}
            onClick={() => setActiveTab('details')}
          >
            Details
          </button>
        </div>
      </div>
              
      {activeTab === 'chart' ? (
        <div className="mb-4">
          <FinancialChart   tickerName={stock.ticker}
            data={chartData.data}
            loading={chartData.loading}
            error={chartData.error}
            entry={stock.entry_point}
            exit={stock.exit_point}
            stopLoss={calculateStopLoss(stock?.entry_point)}
            ratio={stock?.pe_ratio ? stock?.pe_ratio.toFixed(2) : 'N/A'}
            type={type === "Day Trading" ? 'dayTrading': 'default'}
            />
           
        
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 1 }}>
          {/* Details Section */}
          <DetailsText>
            <span style={{ color: "#FFFFFF", fontWeight: "bold" }}>Volume:</span>{" "}
            <span style={{ color: "#D4F1F4", fontWeight: "bold" }}>
              {stock?.volume ? stock?.volume.toLocaleString() : "N/A"}
            </span>
          </DetailsText>
          <DetailsText>
            <span style={{ color: "#FFFFFF", fontWeight: "bold" }}>Market Cap:</span>{" "}
            <span style={{ color: "#D4F1F4", fontWeight: "bold" }}>
              {stock?.market_cap ? stock?.market_cap.toLocaleString() : "N/A"}
            </span>
          </DetailsText>
          <DetailsText>
            <span style={{ color: "#FFFFFF", fontWeight: "bold" }}>P/E Ratio:</span>{" "}
            <span style={{ color: "#D4F1F4", fontWeight: "bold" }}>
              {stock?.pe_ratio ? stock?.pe_ratio.toFixed(2) : "N/A"}
            </span>
          </DetailsText>
          <DetailsText>
            <span style={{ color: "#FFFFFF", fontWeight: "bold" }}>Buy Signal:</span>{" "}
            <span style={{ color: "cyan", fontWeight: "bold" }}>
              ${stock?.entry_point ? stock?.entry_point : "N/A"}
            </span>
          </DetailsText>
          <DetailsText>
            <span style={{ color: "#FFFFFF", fontWeight: "bold" }}>Sell Signal:</span>{" "}
            <span style={{ color: "cyan", fontWeight: "bold" }}>
              ${stock?.exit_point ? stock?.exit_point : "N/A"}
            </span>
          </DetailsText>
          <DetailsText>
            <span style={{ color: "#FFFFFF", fontWeight: "bold" }}>Stop Loss:</span>{" "}
            <span style={{ color: "#D4F1F4", fontWeight: "bold" }}>
              ${calculateStopLoss(stock?.entry_point)}
            </span>
          </DetailsText>
        </div>
      
        <div style={{ }}>
          {/* Image Section */}
          <img 
            src="/whiteLogo.png" 
            alt="stock image" 
            style={{ width: '200px', height: 'auto' }} 
          />
        </div>
      </div>
      
      )}
 
      <AIAnalysisText>
        <u style={{ color: "white", fontWeight: "bold", fontSize: "17px", marginBottom: "3px" }}>
          <strong>Why </strong>{stock?.ticker}?
        </u>
        <span style={{ color: "#FFFFFF", fontSize: "15px" }}> {aiAnalysis}</span>
      </AIAnalysisText>

      <RiskScaleContainer>
        <RiskLabel>Risk Level:</RiskLabel>
        <RiskBar>
          <RiskIndicator riskLevel={calculateRisk(stock)} />
        </RiskBar>
      </RiskScaleContainer>

      <NewsContainer>
        <h4 style={{ fontSize: "23px", color: "#FFFFFF", fontWeight: "bold", textAlign: "center" }}>
          Latest News
        </h4>
        <ArrowContainer onClick={() => toggleNews(stock.ticker, stock.name)}>
          <Arrow isExpanded={newsExpanded[stock.ticker]} />
        </ArrowContainer>
        {newsExpanded[stock.ticker] &&
          news[stock.ticker]?.map((article, index) => (
            <NewsItem key={index}>
              <NewsThumbnail src={article.image_url || Logo} alt="News Thumbnail" />
              <NewsTitle href={article.url} target="_blank" rel="noopener noreferrer">
                {article.title}
              </NewsTitle>
            </NewsItem>
          ))}
        {!newsExpanded[stock.ticker] && (
          <p style={{ textAlign: "center", color: "white", marginTop: "13px" }}>
            Tap the arrow for news
          </p>
        )}
      </NewsContainer>
    </StockItem>
  );
};

export const SOTD = () => {
  const [data, setData] = useState({});
  const [contentLoaded, setContentLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState("Day Trading");
  const [loading, setLoading] = useState(false);
  const [tabLoading, setTabLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    "Analyzing stock data..."
  );
  const [guestMode, setGuestMode] = useState(null);
  const [showAd, setShowAd] = useState(false);
  const [adWatched, setAdWatched] = useState(false);
  const [shouldGrantAccess, setShouldGrantAccess] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false); // Add this line here
  const wsRef = useRef(null);
  const activeTabRef = useRef(activeTab);
  const navigate = useNavigate();
  const { isAuthenticated, user, reduceUserAttempts, remainingAttempts } =
    useAuth();
  const token = localStorage.getItem("token");
  const [updateStatus, setUpdateStatus] = useState("fully updated");
  const [icon, setIcon] = useState("✅"); // Checkmark icon
  const [lastUpdated, setLastUpdated] = useState(format(new Date(), "PPpp"));
  const [news, setNews] = useState({});
  const [newsExpanded, setNewsExpanded] = useState({}); // Tracks expanded state per stock
  const [enableSidebar, setEnableSidebar] = useState(false);
  
  const bottomStocksRef = useRef(null);



  const generateDummyStocks = () => {
    return Array.from({ length: 5 }, (_, index) => ({
      name: "Hidden Stock",
      ticker: "XXX",
      price: "XX.XX",
      change_percent: Math.random() > 0.5 ? 2.5 : -2.5,
      volume: "X,XXX,XXX",
      market_cap: "XX,XXX,XXX",
      pe_ratio: "XX.XX",
      entry_point: "XX.XX",
      exit_point: "XX.XX",
      analysis: "This analysis is hidden until you watch the ad or upgrade to SOTD+",
    }));
  };

  const organizeStocks = (stocks) => {
    if (!stocks || stocks.length === 0) return { dummyStocks: [], realStocks: [] };
    
    // Generate 5 dummy stocks
    const dummyStocks = generateDummyStocks();
    
    // Take first 2 real stocks
    const realStocks = stocks;
    
    return { dummyStocks, realStocks };
  };
  // useEffect(() => {
  //   if (showAd) {
  //     // Load Adsterra script
  //     const script = document.createElement('script');
  //     script.type = 'text/javascript';
  //     script.src = '//pl25509578.profitablecpmrate.com/e2/d4/8c/e2d48c950a9234876b18f877553d76d8.js';
  //     script.async = true;
      
  //     // Handle script load success
  //     script.onload = () => {
  //       // Auto complete ad view after a delay
  //       setTimeout(() => {
  //         handleAdComplete();
  //       }, 2000); // 2 second delay before marking as complete
  //     };
      
  //     // Handle script load error
  //     script.onerror = () => {
  //       console.error('Failed to load Adsterra script');
  //       handleAdComplete(); // Fallback to complete in case of error
  //     };
      
  //     document.body.appendChild(script);
      
  //     // Cleanup
  //     return () => {
  //       if (document.body.contains(script)) {
  //         document.body.removeChild(script);
  //       }
  //     };
  //   }
  // }, [showAd]);
  
  // useEffect(() => {
  //   if (remainingAttempts === 0 || !isAuthenticated) {
  //     // Banner Ad Script
  //     const optionsScript = document.createElement('script');
  //     optionsScript.type = 'text/javascript';
  //     optionsScript.text = `
  //       atOptions = {
  //         'key' : '589cd924deef4e0d95aab03d9a0f274d',
  //         'format' : 'iframe',
  //         'height' : 90,
  //         'width' : 728,
  //         'params' : {}
  //       };
  //     `;
      
  //     // Create script element for ad
  //     const adScript = document.createElement('script');
  //     adScript.type = 'text/javascript';
  //     adScript.src = '//www.highperformanceformat.com/589cd924deef4e0d95aab03d9a0f274d/invoke.js';
  //     adScript.async = true;
      
  //     // Append scripts to document
  //     let bannerContainer = document.getElementById("banner-ad-container")
  //     bannerContainer.appendChild(optionsScript);
  //     bannerContainer.appendChild(adScript);

  //     // Native Ad Script
      


  //     return () => {
        
  //       if (bannerContainer) {
  //         bannerContainer.removeChild(optionsScript);
  //         bannerContainer.removeChild(adScript);
  //       }
      
  //     };
  //   }
  // }, [remainingAttempts,isAuthenticated]);
  const calculateRisk = (stock) => {
    if (!stock) return "medium";
    const { price, change_percent, pe_ratio, volume } = stock;

    if (
      price < 5 &&
      change_percent > 5 &&
      (pe_ratio < 10 || pe_ratio > 50) &&
      volume > 1_000_000
    ) {
      return "high";
    } else if (
      (price >= 5 && price <= 20) ||
      (change_percent >= 2 && change_percent <= 5) ||
      volume < 1_000_000
    ) {
      return "medium";
    } else {
      return "low";
    }
  };

  const toggleNews = async (ticker, companyName) => {
    setNewsExpanded((prev) => ({
      ...prev,
      [ticker]: !prev[ticker],
    }));

    // Fetch news only if it hasn't been fetched yet
    if (!news[ticker]) {
      const fetchedNews = await fetchStockNews(ticker, companyName);
      setNews((prev) => ({ ...prev, [ticker]: fetchedNews.slice(0, 2) }));
    }
  };

  const fetchStockNews = async (ticker, companyName) => {
    try {
      const response = await axios.get(
        `https://api.thenewsapi.com/v1/news/all`,
        {
          params: {
            api_token: "hIPyQahohBDNjPzUD6p0t1PRubBo0EgdcfJdw06E",
            search: ticker || companyName,
            language: "en",
            page: 1,
            per_page: 3, // Limit API results to 2 articles
          },
        }
      );
      console.log(`News for ${ticker || companyName}:`, response.data.data); // Debugging: Log results
      return response.data.data || [];
    } catch (error) {
      console.error(`Error fetching news for ${ticker || companyName}:`, error);
      return [];
    }
  };

  const fetchNewsForStocks = async (stocks) => {
    const newsData = {};
    for (const stock of stocks) {
      const stockNews = await fetchStockNews(stock.ticker, stock.name);
      newsData[stock.ticker] = stockNews.slice(0, 2); // Ensure only the top 2 articles are kept
    }
    setNews(newsData);
  };

  useEffect(() => {
    if (data[activeTab]?.length > 0) {
      console.log("Fetching news for stocks:", data[activeTab]); // Debugging: Ensure stock data is available
      fetchNewsForStocks(data[activeTab]); // Call fetchNewsForStocks when stock data is ready
    }
  }, [data, activeTab]); // Trigger this effect when stock data or the active tab changes

  useEffect(() => {
    const interval = setInterval(() => {
      setIsUpdating(true);
      setUpdateStatus("updating data");

      // Simulate buffering animation for 2 seconds
      setTimeout(() => {
        setIsUpdating(false);
        setUpdateStatus("fully updated");
        setLastUpdated(format(new Date(), "PPpp"));
      }, 2000);
    }, Math.random() * (30000 - 15000) + 15000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    activeTabRef.current = activeTab;
  }, [activeTab]);

  useEffect(() => {
    checkAccessStatus();
  }, [isAuthenticated, user, remainingAttempts]);

  const checkAccessStatus = () => {
    if (!isAuthenticated) {
      setShouldGrantAccess(true);
      return;
    }

    if (user) {
      const hasActiveMembership =
        user.membership && user.membership.status === "ACTIVE";
      if (hasActiveMembership) {
        setShouldGrantAccess(false);
        return;
      }

      if (!hasActiveMembership && remainingAttempts > 0) {
        setShouldGrantAccess(false);
        return;
      }

      if (!hasActiveMembership && remainingAttempts === 0) {
        setShouldGrantAccess(true);
        return;
      }
    }
  };

  const cleanupWebSocket = useCallback(() => {
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
  }, []);

  const connectWebSocket = useCallback(
    (category = null) => {
      cleanupWebSocket();

      // const wsUrl = new URL(`${process.env.REACT_APP_WS_URL || 'ws://localhost:3000/ws'}`);
      const wsUrl = new URL(`wss://sotd.ai/ws`);
      if (token) {
        wsUrl.searchParams.append("token", token);
      }
      if (category) {
        wsUrl.searchParams.append("category", category);
      }

      const newWs = new WebSocket(wsUrl.toString());
      wsRef.current = newWs;

      newWs.onopen = () => {
        console.log("WebSocket connected");
        if (category) {
          newWs.send(
            JSON.stringify({
              type: "change_category",
              category: category,
            })
          );
        }
      };

      newWs.onmessage = (event) => {
        const receivedData = JSON.parse(event.data);
        console.log("Received data:", receivedData);

        switch (receivedData.type) {
          case "stock_update":
            if (shouldGrantAccess) {
              handleAccessGranted(true);
            }

            setData((prevData) => {
              // Only update data if it's for the current active tab
              if (receivedData.category === activeTabRef.current) {
                return {
                  ...prevData,
                  [receivedData.category]: receivedData.data,
                };
              }
              return prevData;
            });

            // Only update loading states if it's for the current active tab
            if (receivedData.category === activeTabRef.current) {
              if (receivedData.batchType === "initial") {
                setLoading(false);
                setContentLoaded(true);
              }
              setTabLoading(false);
            }
            break;

          case "category_complete":
            if (receivedData.category === activeTabRef.current) {
              setTabLoading(false);
            }
            break;

          case "complete":
            setContentLoaded(true);
            setLoading(false);
            setTabLoading(false);
            setTimeout(() => {
              bottomStocksRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 500);
            break;

          case "error":
            console.error("Server error:", receivedData.message);
            if (wsRef.current === newWs) {
              setLoading(false);
              setTabLoading(false);
            }
            break;
        }
      };

      newWs.onerror = (error) => {
        console.error("WebSocket error:", error);
        if (wsRef.current === newWs) {
          setLoading(false);
          setTabLoading(false);
        }
      };

      newWs.onclose = () => {
        console.log("WebSocket connection closed");
        if (wsRef.current === newWs) {
          setLoading(false);
          setTabLoading(false);
        }
      };

      return newWs;
    },
    [token, shouldGrantAccess]
  );

  useEffect(() => {
    if (wsRef.current) {
      setTabLoading(true);
      setData((prevData) => ({
        ...prevData,
        [activeTab]: [],
      }));
      connectWebSocket(activeTab);
    }
  }, [activeTab, connectWebSocket]);

  useEffect(() => {
    return () => {
      cleanupWebSocket();
    };
  }, [cleanupWebSocket]);

  const handleInitialLoad = useCallback(() => {
    setLoading(true);
    setEnableSidebar(true); // Enable sidebar after the first click
    reduceUserAttempts(); // Reduce user attempts
    setSelectedModel("SOTD-pro 2.0"); // Automatically select "SOTD-pro 2.0"
    connectWebSocket(activeTab); // Connect WebSocket
    setTimeout(() => {
      setContentLoaded(true); // Simulate data/content loading
      setLoading(false);
    }, 2000);
  }, [activeTab, reduceUserAttempts, connectWebSocket]);
  
  
  // Loading messages effect
  useEffect(() => {
    if (loading || tabLoading) {
      const messages = [
        "Analyzing stock data...",
        "Identifying key market trends...",
        "Calculating RSI indicators...",
        "Assessing market volatility...",
        "Optimizing entry and exit points...",
        "Gathering real-time stock updates...",
      ];
      let index = 0;
      const messageInterval = setInterval(() => {
        setLoadingMessage(messages[index]);
        index = (index + 1) % messages.length;
      }, 2000);

      return () => clearInterval(messageInterval);
    }
  }, [loading, tabLoading]);

  // Rest of your component code remains the same...
  const handleAccessGranted = (isGuest) => {
    setGuestMode(isGuest);
  };

  const handleWatchAd = () => {
    setShowAd(true);
  };

  const handleSOTDPlusClick = () => {
    navigate("/plans");
  };

  const handleAdEnded = () => {
    setShowAd(false);
    setAdWatched(true);
  };

  const handleAdComplete = () => {
    setShowAd(false);
    setAdWatched(true);
    localStorage.setItem("adWatched", Date.now().toString());
    
    // Add smooth scrolling to bottom stocks after a short delay
    setTimeout(() => {
      bottomStocksRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };




  

  return (
    <PageContainer>
      <StockCarouselContainer/>
     
       {/* {(remainingAttempts === 0 || !isAuthenticated) && (
        <AdsContainer shouldShowAds={true}/>
      )}
      {(remainingAttempts === 0 || !isAuthenticated) && (
          <>
      
            
      <div id="banner-ad-container" className="w-[100%]"></div>
            
          
          </>
        )} */}
{contentLoaded && (
  <>
    <Overlay isOpen={isSidebarOpen} onClick={toggleSidebar} />
    <SidebarContainer isOpen={isSidebarOpen}>
      <SidebarTitle>Models:</SidebarTitle>
      {models.map((model, index) => (
        <ModelPill
          key={index}
          isSelected={selectedModel === model.name}
          disabled={model.name !== "SOTD-pro 2.0"}
          onClick={() => {
            if (model.name === "SOTD-pro 2.0") {
              setSelectedModel(model.name);
            }
          }}
        >
          {model.name}
          <ModelDescription>{model.description}</ModelDescription>
        </ModelPill>
      ))}
    </SidebarContainer>
    <SidebarToggleButton isOpen={isSidebarOpen} onClick={toggleSidebar} />
  </>
)}


      {!contentLoaded ? (
        <ButtonContainer>
          {loading ? (
            <>
              <Scene>
                <CubeWrapper>
                  <Cube>
                    <CubeFaces>
                      <CubeFace className="shadow" />
                      <CubeFace className="bottom" />
                      <CubeFace className="top" />
                      <CubeFace className="left" />
                      <CubeFace className="right" />
                      <CubeFace className="back" />
                      <CubeFace className="front" />
                    </CubeFaces>
                  </Cube>
                </CubeWrapper>
              </Scene>
              <LoadingText>{loadingMessage}</LoadingText>
            </>
          ) : (
            <>
              <TapToViewText>Tap to view</TapToViewText>
              <LogoButton onClick={() => handleInitialLoad()}>
                <LogoImageStyled src={Logo} alt="Logo" />
              </LogoButton>
              <StockOfTheDayText>Stocks of The Day</StockOfTheDayText>
            </>
          )}
        </ButtonContainer>
      ) : (
        <>
          <TabContainer>
            <TabButton
              active={activeTab === "Day Trading"}
              onClick={() => setActiveTab("Day Trading")}
              disabled={tabLoading}
            >
              Day Trading
            </TabButton>
            <TabButton
              active={activeTab === "Swing Trading"}
              onClick={() => setActiveTab("Swing Trading")}
              disabled={tabLoading}
            >
              Swing Trading
            </TabButton>
            <TabButton
              active={activeTab === "Long Term"}
              onClick={() => setActiveTab("Long Term")}
              disabled={tabLoading}
            >
              Long Term
            </TabButton>
          </TabContainer>
          <HowDoWeCalculateLink href="/about#our-trading-strategies">
  How do we calculate these?
</HowDoWeCalculateLink>
          {/* Move the status section here */}
          <HeaderStatus>
            <StatusContainer>
              <StatusText isUpdating={isUpdating}>
                {isUpdating ? (
                  <>
                    Updating data <BufferingDots />
                  </>
                ) : (
                  <>
                    Fully Updated <span>✔</span>
                  </>
                )}
              </StatusText>
            </StatusContainer>
            <LastUpdatedText>Last updated: {lastUpdated}</LastUpdatedText>
          </HeaderStatus>

          {tabLoading ? (
            <LoadingContainer>
              <Scene>
                <CubeWrapper>
                  <Cube>
                    <CubeFaces>
                      <CubeFace className="shadow" />
                      <CubeFace className="bottom" />
                      <CubeFace className="top" />
                      <CubeFace className="left" />
                      <CubeFace className="right" />
                      <CubeFace className="back" />
                      <CubeFace className="front" />
                    </CubeFaces>
                  </Cube>
                </CubeWrapper>
              </Scene>
              <LoadingText>{loadingMessage}</LoadingText>
            </LoadingContainer>
          ) : (
            <StockContentWrapper isBlurred={guestMode && !adWatched}>
              <div>
                <div>
                  <h2
                    style={{
                      color: "#ffffff",
                      textAlign: "center",
                      marginTop: "20px",
                      fontSize: "34px",
                      fontWeight: "bold",
                    }}
                  >
                    {activeTab}
                  </h2>
                  <h3
                    style={{
                      color: "#b0b0b0",
                      textAlign: "center",
                      marginBottom: "40px",
                    }}
                  >
                    Top picks for today
                  </h3>
                </div>

                <ul>
               
{data && data[activeTab] && (() => {
              const { dummyStocks, realStocks } = organizeStocks(data[activeTab]);
              
              return (
                <>
                  {/* Render first 5 stocks (blurred) */}
                  {shouldGrantAccess && dummyStocks.map((stock, index) => (
                       <StockItem key={index} isBlurred={true}>
                       <StockHeader>
                         <div>
                         <CompanyName>{index + 1}. {stock.name}</CompanyName>
                           <TickerText>{stock?.ticker}</TickerText>
                         </div>
                         <StockInfo>
                           <PriceText>
                             ${stock.price ? stock?.price : "N/A"}
                           </PriceText>
                           <ChangePercentText
                             positive={stock?.change_percent >= 0}
                           >
                             {stock?.change_percent
                               ? stock?.change_percent.toFixed(2)
                               : "N/A"}
                             %
                           </ChangePercentText>
                         </StockInfo>
                       </StockHeader>
                       <Separator />
                       <DetailsText>
                         <span
                           style={{ color: "#FFFFFF", fontWeight: "bold" }}
                         >
                           Volume:
                         </span>{" "}
                         <span
                           style={{ color: "#D4F1F4", fontWeight: "bold" }}
                         >
                           {stock?.volume
                             ? stock?.volume
                             : "N/A"}
                         </span>
                       </DetailsText>
                       <DetailsText>
                         <span
                           style={{ color: "#FFFFFF", fontWeight: "bold" }}
                         >
                           Market Cap:
                         </span>{" "}
                         <span
                           style={{ color: "#D4F1F4", fontWeight: "bold" }}
                         >
                           {stock?.market_cap
                             ? stock?.market_cap
                             : "N/A"}
                         </span>
                       </DetailsText>
                       <DetailsText>
                         <span
                           style={{ color: "#FFFFFF", fontWeight: "bold" }}
                         >
                           P/E Ratio:
                         </span>{" "}
                         <span
                           style={{ color: "#D4F1F4", fontWeight: "bold" }}
                         >
                           {stock?.pe_ratio
                             ? stock?.pe_ratio
                             : "N/A"}
                         </span>
                       </DetailsText>
                       <DetailsText>
                         <span
                           style={{ color: "#FFFFFF", fontWeight: "bold" }}
                         >
                           Buy Signal:
                         </span>{" "}
                         <span style={{ color: "cyan", fontWeight: "bold" }}>
                           ${stock?.entry_point ? stock?.entry_point : "N/A"}
                         </span>
                       </DetailsText>
                       <DetailsText>
                         <span
                           style={{ color: "#FFFFFF", fontWeight: "bold" }}
                         >
                           Sell Signal:
                         </span>{" "}
                         <span style={{ color: "cyan", fontWeight: "bold" }}>
                           ${stock?.exit_point ? stock?.exit_point : "N/A"}
                         </span>
                       </DetailsText>
                       <DetailsText>
                         <span
                           style={{ color: "#FFFFFF", fontWeight: "bold" }}
                         >
                           Stop Loss:
                         </span>{" "}
                         <span
                           style={{ color: "#D4F1F4", fontWeight: "bold" }}
                         >
                           ${stock?.entry_point}
                         </span>
                       </DetailsText>

                       <AIAnalysisText isBlurred={guestMode && !adWatched}>
                         <u
                           style={{
                             color: "white",
                             fontWeight: "bold",
                             fontSize: "17px",
                             marginBottom: "3px",
                           }}
                         >
                           {" "}
                           {/* Blue and bold for "Why (Company)" */}
                           <strong>Why </strong>
                           {stock?.ticker}?
                         </u>{" "}
                         <span style={{ color: "#FFFFFF", fontSize: "15px" }}>
                           {" "}
                           {/* White for the AI analysis */}
                           {stock?.analysis}
                         </span>
                       </AIAnalysisText>

                       <RiskScaleContainer>
                         <RiskLabel>Risk Level:</RiskLabel>
                         <RiskBar>
                           <RiskIndicator riskLevel={calculateRisk(stock)} />
                         </RiskBar>
                       </RiskScaleContainer>
                       <NewsContainer>
                         <h4
                           style={{
                             fontSize: "23px",
                             color: "#FFFFFF",
                             fontWeight: "bold",
                             textAlign: "center",
                           }}
                         >
                           Latest News
                         </h4>

                         <ArrowContainer
                           onClick={() => toggleNews(stock.ticker, stock.name)}
                         >
                           <Arrow isExpanded={newsExpanded[stock.ticker]} />
                         </ArrowContainer>
                         {newsExpanded[stock.ticker] &&
                           news[stock.ticker]?.map((article, index) => (
                             <NewsItem key={index}>
                               <NewsThumbnail
                                 src={article.image_url || Logo}
                                 alt="News Thumbnail"
                               />
                               <NewsTitle
                                 href={article.url}
                                 target="_blank"
                                 rel="noopener noreferrer"
                               >
                                 {article.title}
                               </NewsTitle>
                             </NewsItem>
                           ))}
                         {!newsExpanded[stock.ticker] && (
                           <p
                             style={{
                               textAlign: "center",
                               color: "white",
                               marginTop: "13px",
                             }}
                           >
                             Tap the arrow for news
                           </p>
                         )}
                       </NewsContainer>
                     </StockItem>
                  ))}
                  
                  {/* Render bottom 2 stocks (only visible after ad) */}
                  {(adWatched || !shouldGrantAccess) && (
                    <div ref={bottomStocksRef}>
                      
                      {realStocks.map((stock, index) => (
  <TabbedStockView
    key={index}
    stock={stock}
    shouldGrantAccess={shouldGrantAccess}
    index={index}
    calculateStopLoss={calculateStopLoss}
    calculateRisk={calculateRisk}
    toggleNews={toggleNews}
    newsExpanded={newsExpanded}
    news={news}
    type={activeTab}
  />
))}
                    </div>
                  )}
                </>
              );
            })()}
                 
                </ul>
              </div>
            </StockContentWrapper>
          )}
               {guestMode && !adWatched && (
            <GuestPopup>
              <GuestViewText>View Today’s Selection</GuestViewText>
              <ButtonWrapper>
                <WatchAdButton onClick={handleWatchAd}>Go with Ads</WatchAdButton>
                <SOTDPlusButton onClick={handleSOTDPlusClick}>SOTD+</SOTDPlusButton>
              </ButtonWrapper>
              <FeatureList>
                <FeatureListItem>Full access to SOTD database</FeatureListItem>
                <FeatureListItem>Real-time entry and exit points</FeatureListItem>
                <FeatureListItem>AI analysis</FeatureListItem>
                <FeatureListItem>Ad free</FeatureListItem>
              </FeatureList>
            </GuestPopup>
          )}
        </>
      )}

      {/* {showAd && <PopUnderAd/>} */}
    {/* Video Ad Popup */}

      {showAd &&   <VideoPopup 
        onVideoComplete={handleAdComplete}
          onClose={() => setShowAd(false)}
        />}
  
      {/* <AdContainer show={showAd}>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=uXlWYZ022zU"
          playing
          controls
          onEnded={handleAdEnded}
          width="80%"
          height="400px"
        />
        <p>Watch the full ad to continue</p>
      </AdContainer> */}
     
      
       {/* {(remainingAttempts === 0 || !isAuthenticated) && (
          <div 
          style={{ 
            width: '100%', 
            minHeight: '150px',
            marginBottom: '20px',
            backgroundColor: '#f0f0f0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          id="container-5e8154d6fd127cf27408d77a2ad5e1bd"
        >
          {!nativeAdLoaded && <span>Loading native advertisement...</span>}
        </div>
       )} */}
       
    </PageContainer>
  );
};
