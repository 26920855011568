import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';

const VerifyEmail = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token'); // Get token from URL query

  useEffect(() => {
    if (!token) {
      setError('Invalid or missing token.');
      setLoading(false);
      return;
    }

    // Call the API to verify the email
    const verifyEmail = async () => {
      try {
        const response = await axiosInstance.get(`/auth/verify-email/${token}`);
        if (response.data.success) {
          setSuccess('Email verified successfully!');
        } else {
          setError('Failed to verify email. Please try again.');
        }
      } catch (err) {
        setError('An error occurred. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, [token]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold text-center text-gray-800 mb-6">Email Verification</h1>
        {loading ? (
          <div className="text-center text-gray-500">Loading...</div>
        ) : success ? (
          <div className="text-center text-green-600">
            <p className="mb-4">{success}</p>
            <button
              onClick={() => navigate('/')}
              className="px-4 py-2 bg-green-600 text-white font-semibold rounded hover:bg-green-700 transition"
            >
              Go to Home
            </button>
          </div>
        ) : error ? (
          <div className="text-center text-red-600">
            <p className="mb-4">{error}</p>
            <button
              onClick={() => navigate('/')}
              className="px-4 py-2 bg-red-600 text-white font-semibold rounded hover:bg-red-700 transition"
            >
              Try Again
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default VerifyEmail;
