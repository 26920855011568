import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES } from './core';
import { Home, SOTD, About, Contact, Plans, SuccessPayment, FailsPayment, ResetPassword } from './modules';
import { Navbar } from './core/components/Navbar';
import VerifyEmail from './modules/VerifyEmail';

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        {/* Redirect root (/) to SOTD page */}
        <Route path="/" element={<Navigate to={ROUTES.SOTD_PATH} />} />

        {/* Other routes */}
        <Route path={ROUTES.SOTD_PATH} element={<SOTD />} />
        <Route path={ROUTES.HOME_PATH} element={<Home />} />
        <Route path={ROUTES.ABOUT_PATH} element={<About />} />
        <Route path={ROUTES.CONTACT_PATH} element={<Contact />} />
        <Route path={ROUTES.PLANS_PATH} element={<Plans />} />
        <Route path={ROUTES.PAYMENT_SUCCESS_PATH} element={<SuccessPayment />} />
        <Route path={ROUTES.PAYMENT_FAILS_PATH} element={<FailsPayment />} />
        <Route path={ROUTES.RESET_PASSWORD_PATH} element={<ResetPassword />} />
        <Route path={ROUTES.VERIFY_EMAIL} element={<VerifyEmail />} />
      </Routes>
    </Router>
  );
};

export default App;
