import styled, { keyframes } from 'styled-components';
import Phone from '../../AppPhone.PNG';
import React, { useState, useEffect } from "react";
import SotdPage from '../../modules/SOTD/sotdpage.png';
import ScanningImage from '../../modules/SOTD/Scanning.jpg';
import FilteringImage from '../../modules/SOTD/AI.jpg';
import TopPicksImage from '../../modules/SOTD/Insights.jpg';
import StockDashboardCarousel from '../../core/components/StockDashboard';




// Keyframes for the cinematic animation
const cinematicCamera = keyframes`
from {
  perspective-origin: 60% 40%;
}
to {
  perspective-origin: 40% 60%;
}
from,
to {
  opacity: 0;
}
25%,
75% {
  opacity: 1;
}
`;


const imagePop = keyframes`
70%,
100% {
  transform: translate3d(0, 0, 60px);
}
`;


const cinematic = keyframes`
from {
  transform: translateZ(-200px) rotateY(30deg) translateY(50vh);
}
to {
  transform: translateZ(-100px) rotateY(-30deg) translateY(-100%) translateY(50vh);
}
`;


const animStar = keyframes`
 from {
   transform: translateY(100vh);
 }
 to {
   transform: translateY(-100vh);
 }
`;






const fadeIn = keyframes`
 from {
   opacity: 0;
   background-color: white;
 }
 to {
   opacity: 1;
   background-color: transparent;
 }
`;


// Styled components for the cinematic animation
const Container = styled.div`
perspective: 1200px;
transform-style: preserve-3d;
animation: ${cinematicCamera} 11s cubic-bezier(0.6, 0, 0.4, 1) both infinite;
&:after {
  content: "";
  background: linear-gradient(to bottom, #000, #0000 20%, #0000 80%, #000);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
`;


const Main = styled.main`
background: #111;
border: solid 1px #222;
padding: 2rem;
max-width: 100%;
width: 960px;
margin: 0 auto;
display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: 2rem;
transform-origin: top center;
transform-style: preserve-3d;
animation: ${cinematic} 11s cubic-bezier(0.6, 0, 0.4, 1) both infinite;
> * {
  grid-column: 1 / -1;
}
img {
  max-width: 100%;
  display: block;
  transform-style: preserve-3d;
  animation: ${imagePop} 11s cubic-bezier(0.6, 0, 0.4, 1) both infinite;
  &:last-of-type {
    animation-delay: 4s;
  }
}
`;


const Header = styled.header`
display: grid;
align-content: center;
grid-column: 1 / -1;
h2.subheader {
  font-size: 2vw;
}
`;


const Callout = styled.div`
text-align: center;
background-color: #3173fa;
padding: 1vw 3vw;
 > p {
  color: white;
}
`;


const StyledH1 = styled.h1`
 line-height: 1.3;
 font-size: 5vw;
 margin: 1rem 0;
 color: white;
`;


const StyledH2 = styled.h2`
 font-size: 4vw;
 margin: 1rem 0;
 color: white;
`;


const StyledH3 = styled.h3`
font-size: 2vw;
font-weight: bold;
`;


const StyledP = styled.p`
line-height: 1.8;
margin: 1rem 0;
color: rgb(158, 166, 184);
`;


const DisclaimerWrapper = styled.div`
 background-color: rgba(0, 0, 0, 0.9);
 color: white;
 padding: 20px;
 border-radius: 10px;
 max-width: 80%; /* Adjusted to be responsive */
 width: 90%; /* Ensures it adapts to screen sizes */
 margin: 20px auto;
 text-align: center;
 position: fixed;
 top: 20%; /* Adjusted for better centering */
 left: 50%;
 transform: translateX(-50%);
 z-index: 1000;


 @media (max-width: 768px) {
   max-width: 95%; /* Adjust for smaller screens */
   padding: 15px; /* Reduce padding for smaller screens */
 }
`;


const AcceptButton = styled.button`
 background-color: white;
 color: black;
 border: none;
 padding: 10px 20px;
 border-radius: 5px;
 font-size: 1rem;
 cursor: pointer;
 margin-top: 20px; /* Add spacing below text */
 width: 100px; /* Ensure consistent button size */
 &:hover {
   background-color: #ccc; /* Subtle hover effect */
 }


 @media (max-width: 768px) {
   width: auto; /* Adjust width for smaller screens */
   font-size: 0.9rem; /* Adjust font size */
 }
`;




const DisclaimerText = styled.p`
 font-size: 1.2rem;
 line-height: 1.5;
 margin-bottom: 20px;


`;




const ShootingStar = styled.div`
 width: 2px;
 height: 2px;
 background: white;
 box-shadow: 0 0 8px white, 0 0 10px white, 0 0 15px white, 0 0 25px white;
 position: absolute;
 top: ${({ top }) => top}%;
 left: ${({ left }) => left}%;
 animation: ${animStar} 20s linear infinite;
 transform-origin: left top;
 animation-fill-mode: forwards;
`;


const HomeWrapper1 = styled.div`
 background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
 width: 100%;
 height: 100%;
 min-height: 4000px; /* Extended static height for better display */
 position: relative;
 top: 0;
 overflow: hidden;
 filter: ${({ blur }) => (blur ? 'blur(8px)' : 'none')};


 @media (max-width: 768px) {
   min-height: 4500px; /* Extra height for smaller screens */
 }
`;












const PhoneWrapper = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 margin: -200px auto 300px; /* Added 150px margin-bottom */
 width: 80%;
 height: auto;
`;








const Phoneimg = styled.img`
 width: 40%;
 height: auto;
 max-width: 400px;
 margin-top: 400px; /* Reduced margin to bring the phone up */
 @media (max-width: 1024px) {
   width: 50%;
 }
 @media (max-width: 768px) {
   width: 60%;
 }
 @media (max-width: 480px) {
   width: 70%;
 }
`;


const TextWrapper = styled.div`
 flex: 1; /* Ensure the text takes up remaining space */
 margin-right: 20px; /* Add spacing from the phone */
 text-align: left;
`;


const DemoBox = styled.div`
 width: 76%;
 height: 26%;
 background-color: #222;
 border-radius: 15px;
 margin: 10px auto; /* Previously 20px auto */
 display: flex;
 justify-content: center;
 align-items: center;
 color: white;
 font-size: 2vw;
 text-align: center;
 margin-top: 143px;
`;






const HomeText1 = styled.div`
 display: flex;
 flex-wrap: wrap;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 font-size: 4.2vw;
 font-family: 'Poppins', sans-serif; /* New modern font */
 margin: 125px 0;
 font-weight: 1000;
 color: #D4F1F4;
 margin-top: 100px;
 z-index: -1;


 @media (max-width: 768px) {
   font-size: 8vw;
   margin-top: 120px;
 }
`;


const HomeText2 = styled.div`
 display: flex;
 flex-wrap: wrap;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 font-size: 1.4vw;
 font-family: 'Poppins', sans-serif; /* New modern font */
 margin: -115px 0;
 color: white;
 margin-top: -100px;


 @media (max-width: 768px) {
   font-size: 4vw;
 }
`;


const HomeText3 = styled.div`
 display: flex;
 flex-wrap: wrap;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 font-size: 1vw; /* Previously 1.3vw */
 font-family: 'Poppins', sans-serif;
 border-radius: 30px;
 border: 2px solid white;
 width: 16%; /* Increased width for a longer button */
 height: 9.5%; /* Slightly increased height */
 margin: 150px auto 80px;
 padding: 8px; /* Adjusted padding for better proportions */
 font-weight: bold;
 color: white;
 cursor: pointer;
 position: relative;
 background-color: ${props => (props.selected ? 'white' : 'transparent')};


 &, a, a:visited, a:hover, a:active {
   color: white !important;
   text-decoration: none;
 }


 &:hover {
   background-color: white;
   color: black !important;
   transition: background-color 0.3s ease-out, color 0.3s ease-out;
 }


 &:hover a {
   color: black !important;
 }


 @media (max-width: 768px) {
   font-size: 3vw;
   width: 50%; /* Adjust width for smaller screens */
 }
`;






const HomeText4 = styled.div`
 display: flex;
 flex-wrap: wrap;
 justify-content: left;
 align-items: left;
 flex-direction: column;
 font-size: 6vw;
 font-family: Ubuntu;
 margin: 200px 0; /* Reduced margin-top */
 position: relative;
 font-weight: bold;
 color: white;
 top: 10px; /* Adjusted top to move higher */
 margin-left: 40px;


 @media (max-width: 768px) {
   font-size: 8vw;
   margin-top: 100px;
 }
`;


const HomeText5 = styled.div`
 display: flex;
 flex-wrap: wrap;
 justify-content: left;
 align-items: left;
 flex-direction: column;
 font-size: 2vw;
 font-family: Ubuntu;
 margin: -250px 0; /* Adjusted margin to align with HomeText4 */
 position: relative;
 margin-left: 40px;
 color: #D4F1F4;
 top: 75px; /* Adjusted top to move higher */


 @media (max-width: 768px) {
   font-size: 4vw;
 }
`;




const WinnersSection = styled.div`
 background-color: #1b1b1b;
 padding: 2rem;
 margin: 15rem auto 2rem; /* Increased margin-top */
 border-radius: 10px;
 max-width: 80%;
 text-align: center;
`;




const WinnerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Smaller grid items */
  gap: 1.5rem;
  justify-content: center; /* Centers the grid horizontally */
  align-items: center; /* Centers the grid vertically */
  width: 100%; /* Adjusts the overall size */
  margin: 0 auto; /* Centers the grid within its container */
`;



const WinnerCard = styled.div`
 background-color: #2a2a2a;
 color: white;
 padding: 1.5rem;
 border-radius: 10px;
 box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
 transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
   transform: translateY(-5px);
   box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
 }
`;


const StockHeader = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 margin-bottom: 1rem;
`;


const StockName = styled.h3`
 font-size: 1.5rem;
 margin: 0;
`;


const StockDate = styled.span`
 font-size: 0.9rem;
 color: #bbb;
`;


const StockDetails = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 margin: 1rem 0;
`;


const StockDetail = styled.div`
 text-align: center;
`;


const StockLabel = styled.span`
 display: block;
 font-size: 0.9rem;
 color: #bbb;
`;


const StockValue = styled.span`
 font-size: 1.2rem;
 font-weight: bold;
 color: #D4F1F4;
`;




const StockTitle = styled.h3`
 margin-bottom: 0.5rem;
 font-size: 1.8rem;
`;


const StockData = styled.p`
 font-size: 1rem;
 margin: 0.3rem 0;
 color: ${(props) => (props.type === "entryExit" ? "#D4F1F4" : "inherit")};
`;




const PercentageChange = styled.div`
 font-size: 1.3rem;
 font-weight: bold;
 color: ${(props) => (props.gain ? "#00c853" : "#e53935")};
`;


const InvestmentDescription = styled.p`
 font-size: 0.95rem;
 color: #ddd;
 margin-top: 1rem;
 line-height: 1.4;
`;


// Styled Components for How It Works Section
















// Styled Components for Improved How It Works Section
const Divider = styled.hr`
 border: none;
 border-top: 1px solid #444;
 margin: 30px 0;
 opacity: 0.5;
`;


const HowItWorksSection = styled.section`
 background-color: #1b1b1b;
 color: #fff;
 padding: 5px 2px;
 text-align: center;
 border-radius: 20px;
 margin: -20px auto -100px; /* Increased margin-top */
 max-width: 90%;
 box-shadow: 0 10px 25px rgba(0, 0, 0, 0.6);
`;




const StepsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px auto;
  max-width: 90%;
  text-align: center;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack steps vertically */
    align-items: center; /* Center-align steps and arrows */
  }
`;


const StepBox = styled.div`
 flex: 1;
 padding: 20px;
 background-color: #2a2a2a;
 border-radius: 15px;
 box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
 color: #d4f1f4;
 margin: 0 10px;
 transition: transform 0.3s ease;


 &:hover {
   transform: translateY(-10px);
   box-shadow: 0 8px 15px rgba(0, 0, 0, 0.7);
 }
`;


const StepNumber = styled.div`
 font-size: 3.5rem;
 font-weight: bold;
 color: #00d4ff;
 margin-bottom: 15px;
`;



const StepArrow = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  color: #ffffff;
  margin: 0 15px;

  @media (max-width: 768px) {
    transform: rotate(90deg); /* Rotate arrow to point downward */
    margin: 15px 0; /* Add margin for spacing between steps */
    display: flex;
    justify-content: center; /* Center the arrow horizontally */
  }
`;


const StepTitle = styled.h3`
 font-size: 1.8rem;
 margin-bottom: 10px;
 color: #d4f1f4;
`;


const StepImage = styled.img`
 width: 100px; /* Slightly reduced size for better balance */
 height: 100px;
 object-fit: cover; /* Ensures the image is cropped proportionally */
 object-position: center; /* Focuses on the center of the image */
 border-radius: 15px; /* Rounded corners */
 margin: 10px auto; /* Adds spacing for cleaner visuals */
 display: block;
 overflow: hidden; /* Ensures no part of the image overflows */
`;




const StepContent = styled.p`
 font-size: 1.1rem;
 line-height: 1.8;
 color: #b0b0b0;
 text-align: left;


 ul {
   margin: 15px 0 0;
   padding-left: 20px;


   li {
     margin-bottom: 10px;
   }
 }
`;

const TopWinnersPreview = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  border: 2px solid white; /* White outline */
  background-color: transparent; /* No fill */
  color: white;
  padding: 10px; /* Reduced padding */
  border-radius: 10px; /* Curved edges */
  text-align: center;
  width: 200px; /* Default width */
  z-index: 1; /* Ensure it remains on top */

  @media (max-width: 768px) {
    top: 10px;
    right: 10px;
    width: 150px; /* Smaller width for smaller screens */
    padding: 8px;
  }

  @media (max-width: 480px) {
    top: 10px;
    right: 5px;
    width: 140px;
    font-size: 0.8rem; /* Reduced font size for small screens */
  }
`;

const PreviewTitle = styled.h3`
  font-size: 1.2rem; /* Slightly smaller font size */
  font-weight: bold;
  margin-bottom: 5px;
`;

const PreviewList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.9rem; /* Adjusted font size */
`;

const PreviewItem = styled.li`
  display: flex;
  flex-direction: column; /* Stack the text vertically */
  margin-bottom: 5px; /* Spacing between items */
  font-size: 0.9rem; /* Consistent font size */
  text-align: left;

  @media (max-width: 480px) {
    font-size: 0.8rem;
    margin-bottom: 3px;
  }
`;

const PercentageGain = styled.span`
  color: #00c853; /* Green color for positive percentage gain */
  font-weight: bold;
  margin-left: 8px; /* Spacing between the percentage and the price range */

  @media (max-width: 480px) {
    margin-left: 5px;
  }
`;

const DownArrows = styled.div`
  position: absolute;
  bottom: -280px; /* Position below the white box */
  right: 100px; /* Align with the bottom-right corner of the box */
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  div {
    width: 20px;
    height: 20px;
    border: solid 2px white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    margin: -5px 0;
    animation: fade 1.5s infinite;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }
    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }

  @keyframes fade {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    bottom: -130px; /* Adjust positioning for smaller screens */
    right: 17px; /* Align properly with the smaller box size */
  }
`;


const TopWinners = ({ winners }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check screen size
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const displayedWinners = isMobile ? winners.slice(0, 1) : winners; // Show only 1 winner on small screens

  const scrollToWinners = () => {
    const winnersSection = document.querySelector("#winners-section");
    winnersSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div style={{ position: "relative" }}>
      <TopWinnersPreview>
        <h3>Top Winners</h3>
        <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
          {displayedWinners.map((stock, index) => {
            const percentageGain = (((stock.exit - stock.entry) / stock.entry) * 100).toFixed(2);
            return (
              <li key={index} style={{ marginBottom: "5px", textAlign: "left" }}>
                <span>{stock.name}: ${stock.entry} → ${stock.exit}</span>
                <span style={{ color: "#00c853", fontWeight: "bold", marginLeft: "8px" }}>
                  {percentageGain}%
                </span>
              </li>
            );
          })}
        </ul>
      </TopWinnersPreview>
      <DownArrows onClick={scrollToWinners}>
        <div></div>
        <div></div>
        <div></div>
      </DownArrows>
    </div>
  );
  
};





// Final Component
const HowItWorks = () => {
 return (
   <HowItWorksSection>
     <StepsWrapper>
       {/* Step 1 */}
       <StepBox>
         <StepNumber>1</StepNumber>
         <StepImage src={ScanningImage} alt="Data Scanning" />
         <StepTitle>Real-Time Data Scanning</StepTitle>
         <StepContent>
           SOTD continuously scans thousands of stocks across major exchanges like <strong>NASDAQ</strong> and <strong>NYSE</strong> using real-time feeds. Our system evaluates:
           <ul>
             <li>
               <strong>Price Movements:</strong> Tracks breakouts, reversals, and unusual price activity across historical patterns.
             </li>
             <li>
               <strong>Volume Trends:</strong> Identifies sudden spikes that signify institutional or insider activity.
             </li>
             <li>
               <strong>Technical Signals:</strong> Combines indicators such as MACD, RSI, and Fibonacci retracement to spot opportunities.
             </li>
           </ul>
         </StepContent>
       </StepBox>


       <StepArrow>→</StepArrow>


       {/* Step 2 */}
       <StepBox>
         <StepNumber>2</StepNumber>
         <StepImage src={FilteringImage} alt="AI Filtering" />
         <StepTitle>AI-Powered Filtering</StepTitle>
         <StepContent>
           Leveraging advanced <strong>machine learning algorithms</strong>, SOTD filters out irrelevant noise and focuses on actionable stocks:
           <ul>
             <li>
               <strong>Predictive Modeling:</strong> Analyzes historical trends to forecast short-term gains with high accuracy.
             </li>
             <li>
               <strong>Custom Filters:</strong> Tailored options for <em>day trading</em> (fast entries), <em>swing trading</em> (short-term holds), and <em>long-term investing</em>.
             </li>
             <li>
               <strong>Risk Elimination:</strong> Eliminates illiquid and volatile stocks to ensure reliability.
             </li>
           </ul>
         </StepContent>
       </StepBox>


       <StepArrow>→</StepArrow>


       {/* Step 3 */}
       <StepBox>
         <StepNumber>3</StepNumber>
         <StepImage src={TopPicksImage} alt="Top Picks" />
         <StepTitle>Daily Actionable Insights</StepTitle>
         <StepContent>
           Every day, SOTD delivers curated stock picks backed by detailed strategies:
           <ul>
             <li>
               <strong>Entry Price:</strong> Calculated using momentum signals, trend lines, and real-time support levels.
             </li>
             <li>
               <strong>Exit Strategy:</strong> Predictive targets based on resistance zones, technical analysis, and profit maximization.
             </li>
             <li>
               <strong>Stop-Loss Recommendations:</strong> Automated thresholds to minimize risks and protect your capital.
             </li>
           </ul>
         </StepContent>
       </StepBox>
     </StepsWrapper>
   </HowItWorksSection>
 );
};














const stockData = [
 { name: "AAPL", entry: 180, exit: 210 },
 { name: "TSLA", entry: 220, exit: 290 },
 { name: "MSFT", entry: 300, exit: 340 },
];


const WinnersList = () => {
 const stockData = [
   { name: "AAPL", entry: 180, exit: 210, date: "12/10/2024" },
   { name: "TSLA", entry: 220, exit: 290, date: "12/11/2024" },
   { name: "MSFT", entry: 300, exit: 340, date: "12/12/2024" },
 ];


 return (
   <WinnersSection>
     <h2 style={{ marginBottom: "1.5rem", color: "white" , fontWeight: "bold", fontSize: "2.8rem"}}>Past Week’s Top Winners</h2>
     <WinnerGrid>
       {stockData.map((stock, index) => {
         const percentageChange = (((stock.exit - stock.entry) / stock.entry) * 100).toFixed(2);
         const profit = ((100 * (stock.exit / stock.entry)) - 100).toFixed(2);


         return (
           <WinnerCard key={index}>
             <StockHeader>
               <StockName>{stock.name}</StockName>
               <StockDate>{stock.date}</StockDate>
             </StockHeader>
             <StockDetails>
               <StockDetail>
                 <StockLabel>Entry</StockLabel>
                 <StockValue>${stock.entry}</StockValue>
               </StockDetail>
               <StockDetail>
                 <StockLabel>Exit</StockLabel>
                 <StockValue>${stock.exit}</StockValue>
               </StockDetail>
             </StockDetails>
             <PercentageChange gain={stock.exit > stock.entry}>
               {percentageChange}% Gain
             </PercentageChange>
             <InvestmentDescription>
               If you invested $100, you would’ve made <strong>${profit}</strong>.
             </InvestmentDescription>
           </WinnerCard>
         );
       })}
     </WinnerGrid>
   </WinnersSection>
 );
};




export const Home = () => {
  const [showSlideshow, setShowSlideshow] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [blurBackground, setBlurBackground] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  useEffect(() => {
    // Disable scrolling during the slideshow
    if (showSlideshow || showDisclaimer) {
      document.body.classList.add('disableScroll');
    } else {
      document.body.classList.remove('disableScroll');
    }
  }, [showSlideshow, showDisclaimer]);

  useEffect(() => {
    if (!disclaimerAccepted) {
      const timer = setTimeout(() => {
        setShowSlideshow(false);
        setShowDisclaimer(true); // Show disclaimer after slideshow ends
        setBlurBackground(true); // Blur background when disclaimer shows
      }, 11000); // Duration of the slideshow animation

      return () => clearTimeout(timer);
    }
  }, [disclaimerAccepted]);

  const handleAccept = () => {
    setShowDisclaimer(false);
    setBlurBackground(false); // Unblur background when disclaimer is accepted
    setDisclaimerAccepted(true); // Ensure it won't show again
  };

  const shootingStars = Array.from({ length: 60 }).map((_, index) => (
    <ShootingStar
      key={index}
      top={Math.random() * 100}
      left={Math.random() * 100}
    />
  ));

  return (
    <div>
      {/* Conditional rendering of TopWinners with explicit z-index */}
      {disclaimerAccepted && (
        <div
          style={{
            position: "relative",
            zIndex: 10,
          }}
        >
          <TopWinners winners={stockData} />
        </div>
      )}

      {showSlideshow ? (
        <Container>
          <Main>
            <Header>
              <StyledH1>SOTD-pro 2.0: The first fully functional AI-powered stock screener leveraging advanced algorithms.</StyledH1>
              <StyledP>Welcome to the future of stock trading. Our AI-driven platform analyzes vast market data to present you with the top-performing stocks daily, empowering your trading decisions with precision and speed.</StyledP>
            </Header>
            <img
              src="https://images.unsplash.com/photo-1558459654-c430be5b0a44?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&ixid=MXwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=960&h=500"
              alt=""
            />
            <section>
              <StyledH2>About the App</StyledH2>
              <StyledP>SOTD is designed for traders who demand accuracy and efficiency. Our AI continuously monitors and evaluates market trends, delivering real-time stock recommendations tailored to your trading strategy.</StyledP>
            </section>
            <section>
              <StyledH2>Our Mission</StyledH2>
              <StyledP>Our mission is to democratize stock trading by providing cutting-edge AI tools that make high-level market analysis accessible to everyone, from novice investors to seasoned traders.</StyledP>
            </section>
            <Callout>
              <StyledH3>Join 10,000+ traders leveraging advanced algorithmic trading.</StyledH3>
              <StyledP>Become part of a growing network of traders who are leveraging AI to stay ahead of the market. With SOTD, you're not just trading smarter—you're joining a movement that's transforming the way people invest.</StyledP>
            </Callout>
            <img src={SotdPage} alt="SOTD Page" />
          </Main>
        </Container>
      ) : (
        <HomeWrapper1 blur={blurBackground}>
          {shootingStars}
          <HomeText1>
            <div>Simplify Trading.</div>
            <div>Amplify Results.</div>
          </HomeText1>
          <HomeText2>
            <div>AI-powered stock screener with</div>
            <div>real-time price entry and exits</div>
          </HomeText2>
          <HomeText3 onClick={() => console.log("Today's watchlist clicked!")}>
            <a href="/sotd" style={{ color: "black" }}>Today's watchlist</a>
          </HomeText3>

          {/* Move HowItWorks higher */}
          <HowItWorks />

          <div style={{ marginBottom: "4rem" }} id="winners-section">
            <WinnersList />
          </div>
          <StockDashboardCarousel/>
          {/* Move DemoBox below WinnersList */}
          <DemoBox>
            <video
              src={require('../../Demo.mp4')}
              controls
              autoPlay
              loop
              muted
              style={{ width: '100%', borderRadius: '15px' }}
            />
          </DemoBox>

          <PhoneWrapper>
            <TextWrapper>
              <HomeText4>
                <div>We are</div>
                <div>Coming soon…</div>
              </HomeText4>
              <HomeText5>
                <div>SOTD app</div>
              </HomeText5>
            </TextWrapper>
            <Phoneimg src={Phone} />
          </PhoneWrapper>
        </HomeWrapper1>
      )}

      {showDisclaimer && (
        <DisclaimerWrapper>
          <DisclaimerText>
            Stock of The Day (SOTD) is a tool intended to assist you in identifying potential stocks to trade. However, it should not be the sole basis for your investment decisions. It's important to conduct your own research and consult with a financial advisor before making any trades. Please note that the regular SOTD only covers US stocks and all information is based on Eastern Standard Time (EST).
          </DisclaimerText>
          <AcceptButton onClick={handleAccept}>I Accept</AcceptButton>
        </DisclaimerWrapper>
      )}
    </div>
  );
};







