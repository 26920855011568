import React, { useEffect, useState, useRef } from 'react';
import { DollarSign, Loader2, TrendingDown, TrendingUp, ChevronLeft, ChevronRight } from 'lucide-react';
import axiosInstance from '../../axiosInstance';

const SP500Carousel = ({ data }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const containerRef = useRef(null);
  
  const stocks = data.sp500Stocks;
  const extendedStocks = [...stocks, ...stocks, ...stocks];
  const stockWidth = 200; // Width of each stock card
  const totalWidth = stocks.length * stockWidth;

  useEffect(() => {
    let scrollInterval;
    
    if (!isPaused && !isDragging) {
      scrollInterval = setInterval(() => {
        setScrollPosition((prev) => {
          const newPosition = prev + 1;
          if (newPosition >= totalWidth) {
            return 0;
          }
          return newPosition;
        });
      }, 30);
    }

    return () => clearInterval(scrollInterval);
  }, [isPaused, isDragging, stocks.length, totalWidth]);

  const handleMouseEnter = () => setIsPaused(true);
  const handleMouseLeave = () => {
    setIsPaused(false);
    setIsDragging(false);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollPosition);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    
    e.preventDefault();
    const x = e.pageX - startX;
    const newScrollPosition = Math.max(0, Math.min(x, totalWidth));
    setScrollPosition(newScrollPosition);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const scroll = (direction) => {
    const scrollAmount = direction === 'left' ? -stockWidth : stockWidth;
    setScrollPosition((prev) => {
      const newPosition = prev + scrollAmount;
      if (newPosition < 0) {
        return totalWidth - stockWidth;
      }
      if (newPosition >= totalWidth) {
        return 0;
      }
      return newPosition;
    });
  };

  return (
    <div className="relative w-full bg-[#1E1E1E] shadow-lg rounded-lg overflow-hidden border border-gray-800">
      {/* Navigation Buttons */}
      <button 
        onClick={() => scroll('left')}
        className="absolute left-0 top-1/2 -translate-y-1/2 z-10 p-2 bg-gray-900/80 hover:bg-gray-900 text-gray-300 rounded-r"
      >
        <ChevronLeft className="w-6 h-6" />
      </button>
      <button 
        onClick={() => scroll('right')}
        className="absolute right-0 top-1/2 -translate-y-1/2 z-10 p-2 bg-gray-900/80 hover:bg-gray-900 text-gray-300 rounded-l"
      >
        <ChevronRight className="w-6 h-6" />
      </button>

      {/* Carousel Content */}
      <div 
        ref={containerRef}
        className="overflow-hidden"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
      >
        <div 
          className="flex transition-transform duration-1000 ease-linear"
          style={{ transform: `translateX(-${scrollPosition}px)` }}
        >
          {extendedStocks.map((stock, index) => (
            <div
              key={`${stock.ticker}-${index}`}
              className="flex-none w-[200px] p-3 border-r border-gray-800 hover:bg-gray-900 transition-colors"
            >
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-2">
                    <div className="bg-blue-900/30 rounded p-1">
                      <DollarSign className="w-4 h-4 text-blue-400" />
                    </div>
                    <span className="font-bold text-gray-100">{stock.ticker}</span>
                  </div>
                  <div className={`flex items-center ${
                    parseFloat(stock.change_percent) >= 0 ? 'text-green-400' : 'text-red-400'
                  }`}>
                    {parseFloat(stock.change_percent) >= 0 ? (
                      <TrendingUp className="w-4 h-4" />
                    ) : (
                      <TrendingDown className="w-4 h-4" />
                    )}
                    <span className="text-sm font-medium ml-1">
                      {Math.abs(parseFloat(stock.change_percent)).toFixed(1)}%
                    </span>
                  </div>
                </div>

                <div className="text-xs text-gray-400 truncate">
                  {stock.name}
                </div>

                <div className="flex justify-between items-center text-sm">
                  <span className="font-bold text-gray-100">
                    ${parseFloat(stock.price).toLocaleString()}
                  </span>
                  <span className="text-gray-400 text-xs">
                    Vol: {(stock.volume / 1e6).toFixed(1)}M
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const StockCarouselContainer = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stockData, setStockData] = useState(null);

  useEffect(() => {
    const fetchStockData = async () => {
      try {
        const response = await axiosInstance.get('/sotd/stocks');
        setStockData(response.data);
        setError(null);
      } catch (err) {
        setError(err.message || 'Failed to fetch stock data');
      } finally {
        setLoading(false);
      }
    };

    fetchStockData();
    const refreshInterval = setInterval(fetchStockData, 5 * 60 * 1000);
    return () => clearInterval(refreshInterval);
  }, []);

  if (loading) {
    return (
      <div className="w-full h-24 flex items-center justify-center bg-[#1E1E1E] rounded-lg border border-gray-800">
        <Loader2 className="w-6 h-6 text-blue-400 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full p-3 bg-red-900/20 rounded-lg border border-red-800">
        <p className="text-red-400 text-sm text-center">Error: {error}</p>
      </div>
    );
  }

  if (!stockData?.sp500Stocks?.length) {
    return (
      <div className="w-full p-3 bg-[#1E1E1E] rounded-lg border border-gray-800">
        <p className="text-gray-400 text-sm text-center">No data available</p>
      </div>
    );
  }

  return <SP500Carousel data={stockData} />;
};

export default StockCarouselContainer;