import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CategoryScale } from 'chart.js';
import { CandlestickController, CandlestickElement, OhlcElement } from 'chartjs-chart-financial';
import { ArrowDownCircle, BarChart2, TrendingUp, TrendingDown, DollarSign } from 'lucide-react';

Chart.register(CategoryScale, CandlestickController, CandlestickElement, OhlcElement, annotationPlugin);

const TechnicalIndicator = ({ label, value, isPositive = null, icon: Icon }) => (
  <div className="flex items-center gap-2 p-2 bg-gray-800 rounded">
    <Icon size={16} className={isPositive === null ? 'text-gray-400' : isPositive ? 'text-green-500' : 'text-red-500'} />
    <div>
      <div className="text-xs text-gray-400">{label}</div>
      {label.toLowerCase() !== 'volume' && <div className="text-sm font-semibold text-gray-100">{value}</div>}
    </div>
  </div>
);

const CandlestickChart = ({ 
  tickerName, 
  data, 
  error, 
  loading, 
  entry, 
  exit, 
  stopLoss,
  ratio,
  type = 'default' // New prop to handle different chart views
}) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (!data || !chartRef.current) return;

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');

    const chartData = data.candlestickData.map(item => ({
      x: new Date(item.time).getTime(),
      o: item.open,
      h: item.high,
      l: item.low,
      c: item.close
    }));

    const ma20Data = data.candlestickData.map(item => ({
      x: new Date(item.time).getTime(),
      y: item.technicals?.ma20 || null
    })).filter(item => item.y !== null);

    const ma50Data = data.candlestickData.map(item => ({
      x: new Date(item.time).getTime(),
      y: item.technicals?.ma50 || null
    })).filter(item => item.y !== null);

    const volumeData = data.candlestickData.map(item => ({
      x: new Date(item.time).getTime(),
      y: item.volume,
      color: item.analysis?.volume?.aboveAverage ? 'rgba(34, 197, 94, 0.3)' : 'rgba(239, 68, 68, 0.3)'
    }));

    chartInstance.current = new Chart(ctx, {
      type: 'candlestick',
      data: {
        datasets: [
          {
            label: data.symbol,
            data: chartData,
            color: {
              up: '#22c55e',
              down: '#ef4444',
              unchanged: '#6b7280',
            }
          },
          {
            type: 'line',
            label: '20 MA',
            data: ma20Data,
            borderColor: '#eab308',
            borderWidth: 1.5,
            pointRadius: 0,
            fill: false
          },
          {
            type: 'line',
            label: '50 MA',
            data: ma50Data,
            borderColor: '#8b5cf6',
            borderWidth: 1.5,
            pointRadius: 0,
            fill: false
          },
          {
            type: 'bar',
            label: 'Volume',
            data: volumeData,
            backgroundColor: volumeData.map(d => d.color),
            yAxisID: 'volume'
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false, // Important to allow dynamic resizing
        layout: {
          padding: {
            right:  0
          }
        },
        scales: {
          x: {
            time: {
              unit: type === 'dayTrading' ? 'hour' : 'day',
              displayFormats: {
                day: 'MMM d',
                hour: 'HH:mm'
              }
            },
            grid: {
              display: true,
              color: 'rgba(75, 85, 99, 0.2)'
            },
            ticks: {
              color: '#e5e7eb'
            }
          },
          y: {
            position: 'right',
            grid: {
              color: 'rgba(75, 85, 99, 0.2)'
            },
            ticks: {
              color: '#e5e7eb',
              callback: value => `$${value.toFixed(2)}`
            }
          },
          volume: {
            position: 'left',
            grid: {
              display: false
            },
            ticks: {
              color: '#e5e7eb',
              callback: value => `${(value / 1e6).toFixed(1)}M`
            }
          }
        },
        plugins: {
          annotation: {
            annotations: {
              ...(entry && {
                entryLine: {
                  type: 'line',
                  yMin: entry,
                  yMax: entry,
                  borderColor: '#3b82f6',
                  borderWidth: 1,
                  borderDash: [5, 5]
                }
              }),
              ...(exit && {
                targetLine: {
                  type: 'line',
                  yMin: exit,
                  yMax: exit,
                  borderColor: '#22c55e',
                  borderWidth: 1,
                  borderDash: [5, 5]
                }
              }),
              ...(stopLoss && {
                stopLossLine: {
                  type: 'line',
                  yMin: stopLoss,
                  yMax: stopLoss,
                  borderColor: '#ef4444',
                  borderWidth: 1,
                  borderDash: [5, 5]
                }
              })
            }
          },
          legend: {
            display: true,
            position: 'top',
            labels: {
              color: '#e5e7eb',
              usePointStyle: true
            }
          },
          tooltip: {
            mode: 'index',
            intersect: false,
            backgroundColor: '#1f2937',
            titleColor: '#f3f4f6',
            bodyColor: '#f3f4f6',
            borderColor: '#4b5563',
            borderWidth: 1,
            callbacks: {
              label: function(context) {
                const dataset = context.dataset;
                if (dataset.type === 'candlestick') {
                  const point = context.raw;
                  const item = data.candlestickData[context.dataIndex];
                  return [
                    `Open: $${point.o.toFixed(2)}`,
                    `High: $${point.h.toFixed(2)}`,
                    `Low: $${point.l.toFixed(2)}`,
                    `Close: $${point.c.toFixed(2)}`,
                    `Volume: ${item.volume.toLocaleString()}`,
                    `Change: ${item.analysis?.priceChangePercent?.toFixed(2)}%`,
                    item.analysis?.patterns?.isHammer ? '🔨 Hammer Pattern' : '',
                    item.analysis?.patterns?.isInvertedHammer ? '🔄 Inverted Hammer' : '',
                    item.analysis?.patterns?.isDoji ? '✨ Doji Pattern' : ''
                  ].filter(Boolean);
                }
                return context.formattedValue;
              }
            }
          }
        }
      }
    });
  }, [data, entry, exit, stopLoss, type]);

  const formatNumber = (num) => {
    if (!num) return '0';
    if (num >= 1e9) return `${(num / 1e9).toFixed(2)}B`;
    if (num >= 1e6) return `${(num / 1e6).toFixed(2)}M`;
    if (num >= 1e3) return `${(num / 1e3).toFixed(2)}K`;
    return num;
  };

  const getMarketTrendIcon = () => {
    if (!data?.signals?.marketTrend) return <BarChart2 className="text-gray-400" />;
    switch (data.signals.marketTrend) {
      case 'bullish': return <TrendingUp className="text-green-500" />;
      case 'bearish': return <TrendingDown className="text-red-500" />;
      default: return <BarChart2 className="text-gray-400" />;
    }
  };

  if (loading) {
    return (
      <div className="w-full p-4 bg-gray-900 rounded-lg shadow-xl flex items-center justify-center h-[500px] sm:h-[600px]">
        <div className="text-gray-400">Loading chart data...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full p-4 bg-gray-900 rounded-lg shadow-xl flex items-center justify-center h-[500px] sm:h-[600px]">
        <div className="text-red-500">Error: {error}</div>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="w-full p-4 bg-gray-900 rounded-lg shadow-xl flex items-center justify-center h-[500px] sm:h-[600px]">
        <div className="text-gray-400">No data available</div>
      </div>
    );
  }

  return (
    <div className="w-full p-4 bg-gray-900 rounded-lg shadow-xl">
      <div className="flex items-center justify-between mb-4">
        <div>
          <div className="text-lg font-bold text-gray-100 flex items-center gap-2">
            {data.name} ({data.symbol})
            {getMarketTrendIcon()}
          </div>
          <div className="flex gap-4 text-sm text-gray-400">
            <span>{data.metadata?.exchange}</span>
            <span>Market Cap: {formatNumber(data.metadata?.marketCap)}</span>
            <span>{data.metadata?.currency}</span>
          </div>
        </div>
        <div className="text-right">
          <div className="text-sm text-gray-400">Current Price</div>
          <div className="text-lg font-bold text-gray-100">
            ${data.currentPrice?.toFixed(2) || '0.00'}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-2 mb-4">
        <TechnicalIndicator 
          label="Market Cap" 
          value={formatNumber(data.metadata?.marketCap)}
          isPositive={true}
          icon={TrendingUp}
        />
        <TechnicalIndicator 
          label="P/E Ratio" 
          value={ratio}
          icon={DollarSign}
        />
        <TechnicalIndicator 
          label="Volume" 
          value={formatNumber(data.averageVolume)}
          isPositive={data.candlestickData?.[data.candlestickData.length - 1]?.analysis?.volume?.aboveAverage}
          icon={BarChart2}
        />
        <TechnicalIndicator 
          label="Daily Change" 
          value={`${data.candlestickData?.[data.candlestickData.length - 1]?.analysis?.priceChangePercent?.toFixed(2) || '0.00'}%`}
          isPositive={data.candlestickData?.[data.candlestickData.length - 1]?.analysis?.priceChangePercent > 0}
          icon={ArrowDownCircle}
        />
      </div>

      <div className="h-[300px] sm:h-[500px]">
        <canvas ref={chartRef}></canvas>
      </div>

      <div className="grid grid-cols-3 gap-4 mt-4 text-sm text-gray-300">
        {entry && (
          <div className="flex items-center justify-center gap-2 bg-gray-800 p-2 rounded">
            <div className="w-3 h-3 bg-blue-500 rounded-full"></div>
            <span>Entry ${entry}</span>
          </div>
        )}
        {exit && (
          <div className="flex items-center justify-center gap-2 bg-gray-800 p-2 rounded">
            <div className="w-3 h-3 bg-green-500 rounded-full"></div>
            <span>Target ${exit}</span>
          </div>
        )}
        {stopLoss && (
          <div className="flex items-center justify-center gap-2 bg-gray-800 p-2 rounded">
            <div className="w-3 h-3 bg-red-500 rounded-full"></div>
            <span>Stop ${stopLoss}</span>
          </div>
        )}
      </div>

      <div className="text-xs text-gray-400 text-right mt-2">
        Last updated: {data.metadata?.lastUpdated ? new Date(data.metadata.lastUpdated).toLocaleString() : 'N/A'}
      </div>
    </div>
  );
};

export default CandlestickChart;


