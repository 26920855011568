import axios from 'axios';

const axiosInstance = axios.create({
  // Base URL of your API (You can change it depending on your backend API URL)
  baseURL: 'https://sotd.ai/api', // Replace with your API's base URL
// baseURL: 'http://localhost:3000/api',
  // Optional: you can set the headers here
  headers: {
    'Content-Type': 'application/json',
    // Add your auth token or any other headers you may 
    // 'Authorization': `Bearer ${yourAuthToken}`
  },
  timeout: 300000,
});

// Optional: Request Interceptor to add token or other logic
axiosInstance.interceptors.request.use(
  (config) => {
    // Check if you have a token in localStorage or Redux store and add it to headers
    const token = localStorage.getItem('token'); // or from Redux, Context, etc.
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Optional: Response Interceptor to handle errors globally
axiosInstance.interceptors.response.use(
  (response) => {
    // You can do something with the response, like logging or modifying it
    return response;
  },
  (error) => {
    // You can handle errors globally (e.g., show a global error notification)
    if (error.response && error.response.status === 401) {
      // Handle unauthorized errors, e.g., redirect to login
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
