import React, { useState, useEffect, useRef } from "react"
import { TrendingUp, DollarSign, Calculator, ChevronUp, Wallet, ChevronDown, X } from "lucide-react"

const ExpandableProfitCalculator = ({ entryPoint, exitPoint }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [investmentType, setInvestmentType] = useState("shares")
  const [shares, setShares] = useState(100)
  const [investmentAmount, setInvestmentAmount] = useState(1000)
  const [profit, setProfit] = useState(0)
  const [percentageGain, setPercentageGain] = useState(0)
  const [isCalculating, setIsCalculating] = useState(false)
  const calculatorRef = useRef(null)
  const inputRef = useRef(null)

  // Handle clicking outside to close
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calculatorRef.current && !calculatorRef.current.contains(event.target)) {
        setIsExpanded(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleSharesChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "")
    setShares(value === "" ? 0 : Number.parseInt(value))
  }

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "")
    setInvestmentAmount(value === "" ? 0 : Number.parseInt(value))
  }

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp") {
      e.preventDefault()
      if (investmentType === "shares") {
        setShares((prev) => Math.min(prev + 1, 10000))
      } else {
        setInvestmentAmount((prev) => Math.min(prev + 100, 1000000))
      }
    } else if (e.key === "ArrowDown") {
      e.preventDefault()
      if (investmentType === "shares") {
        setShares((prev) => Math.max(prev - 1, 1))
      } else {
        setInvestmentAmount((prev) => Math.max(prev - 100, 100))
      }
    }
  }

  const calculateProfit = () => {
    setIsCalculating(true)
    const entry = Number.parseFloat(entryPoint)
    const exit = Number.parseFloat(exitPoint)

    let calculatedShares = investmentType === "amount" ? investmentAmount / entry : shares
    const initialInvestment = entry * calculatedShares
    const finalValue = exit * calculatedShares
    const profitValue = finalValue - initialInvestment
    const percentage = ((exit - entry) / entry) * 100

    let currentProfit = 0
    let currentPercentage = 0
    const steps = 20
    const profitIncrement = profitValue / steps
    const percentageIncrement = percentage / steps

    const interval = setInterval(() => {
      currentProfit += profitIncrement
      currentPercentage += percentageIncrement

      setProfit(currentProfit)
      setPercentageGain(currentPercentage)

      if (currentProfit >= profitValue) {
        clearInterval(interval)
        setProfit(profitValue)
        setPercentageGain(percentage)
        setIsCalculating(false)
      }
    }, 30)
  }

  useEffect(() => {
    calculateProfit()
  }, [shares, investmentAmount, entryPoint, exitPoint, investmentType])

  const initialProfit = (Number.parseFloat(exitPoint) - Number.parseFloat(entryPoint)).toFixed(2)

  return (
    <div className="z-10 mt-2" ref={calculatorRef}>
      <div className="relative">
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className={ ` group p-4 rounded-xl text-white flex items-center justify-center gap-3 shadow-lg transition-all duration-300 
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-opacity-50 
            transform hover:scale-102 active:scale-98 ${
              initialProfit >= 0 
                ? "bg-gradient-to-r from-emerald-500 to-green-600 hover:from-emerald-600 hover:to-green-700 focus:ring-green-400" 
                : "bg-gradient-to-r from-rose-500 to-red-600 hover:from-rose-600 hover:to-red-700 focus:ring-red-400"
            }`}
          aria-expanded={isExpanded}
          aria-label="Toggle profit calculator"
        >
          <Calculator className="w-5 h-5 transition-transform group-hover:rotate-12" />
          <span className="text-sm font-semibold">Profit Calculator</span>
          <div
            className={`flex items-center justify-center px-3 py-1.5 rounded-full transition-colors
              ${initialProfit >= 0 
                ? "bg-green-500/30 text-green-100" 
                : "bg-red-500/30 text-red-100"
              }`}
          >
            <span className="text-sm font-medium">
              {initialProfit >= 0 ? "+" : ""}{initialProfit}$
            </span>
          </div>
        </button>

        {isExpanded && (
          <div className="absolute top-full left-0 mt-3 w-96 bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 rounded-xl border border-gray-700 overflow-hidden shadow-2xl backdrop-blur-sm">
            <div className="p-5 space-y-4">
              {/* Header */}
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold text-white">Calculate Potential Profit</h3>
                <button
                  onClick={() => setIsExpanded(false)}
                  className="p-1 rounded-full hover:bg-gray-700 transition-colors"
                >
                  <X className="w-5 h-5 text-gray-400" />
                </button>
              </div>

              {/* Investment Type Toggle */}
              <div className="flex gap-2 p-1 bg-gray-800 rounded-lg">
                {["shares", "amount"].map((type) => (
                  <button
                    key={type}
                    onClick={() => setInvestmentType(type)}
                    className={`flex-1 py-2.5 px-4 rounded-md text-sm font-medium capitalize transition-all duration-200 ${
                      investmentType === type
                        ? "bg-blue-600 text-white shadow-lg transform scale-102"
                        : "text-gray-300 hover:bg-gray-700/50"
                    }`}
                  >
                    By {type}
                  </button>
                ))}
              </div>

              {/* Investment Input */}
              <div className="space-y-3">
                <label className="text-sm font-medium text-gray-300">
                  {investmentType === "shares" ? "Number of Shares" : "Investment Amount ($)"}
                </label>
                <div className="relative">
                  {investmentType === "shares" ? (
                    <input
                      type="text"
                      value={shares}
                      onChange={handleSharesChange}
                      onKeyDown={handleKeyDown}
                      ref={inputRef}
                      className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg text-white 
                        placeholder-gray-500 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 
                        transition-colors"
                      placeholder="Enter number of shares"
                    />
                  ) : (
                    <div className="relative">
                      <span className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400">$</span>
                      <input
                        type="text"
                        value={investmentAmount}
                        onChange={handleAmountChange}
                        onKeyDown={handleKeyDown}
                        ref={inputRef}
                        className="w-full pl-8 pr-4 py-3 bg-gray-800 border border-gray-600 rounded-lg text-white 
                          placeholder-gray-500 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 
                          transition-colors"
                        placeholder="Enter investment amount"
                      />
                    </div>
                  )}
                </div>
                <input
                  type="range"
                  min={investmentType === "shares" ? 1 : 100}
                  max={investmentType === "shares" ? 10000 : 1000000}
                  value={investmentType === "shares" ? shares : investmentAmount}
                  onChange={(e) =>
                    investmentType === "shares"
                      ? setShares(Number.parseInt(e.target.value))
                      : setInvestmentAmount(Number.parseInt(e.target.value))
                  }
                  className="w-full h-2 bg-gray-700 rounded-full appearance-none cursor-pointer 
                    [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 
                    [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-blue-500 
                    [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:cursor-pointer"
                />
              </div>

              {/* Investment Summary */}
              <div className="grid grid-cols-2 gap-4">
                {[
                  { label: "Initial Investment", icon: Wallet, value: investmentType === "shares" ? Number.parseFloat(entryPoint) * shares : investmentAmount },
                  { label: "Final Value", icon: TrendingUp, value: investmentType === "shares" ? Number.parseFloat(exitPoint) * shares : (investmentAmount / Number.parseFloat(entryPoint)) * Number.parseFloat(exitPoint) }
                ].map(({ label, icon: Icon, value }) => (
                  <div key={label} className="bg-gray-800/50 p-4 rounded-lg border border-gray-700/50">
                    <div className="flex items-center gap-2 mb-1.5">
                      <Icon className="w-4 h-4 text-gray-400" />
                      <span className="text-gray-400 text-sm">{label}</span>
                    </div>
                    <div className="text-white font-semibold">
                      ${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </div>
                  </div>
                ))}
              </div>

              {/* Profit Display */}
              <div
                className={`p-5 rounded-lg ${
                  profit >= 0 ? "bg-green-900/20" : "bg-red-900/20"
                } border ${
                  profit >= 0 ? "border-green-500/20" : "border-red-500/20"
                }`}
              >
                <div className="flex justify-between items-center">
                  <div>
                    <div className="text-gray-300 text-sm mb-1">Potential Profit</div>
                    <div className={`text-xl font-bold ${
                      profit >= 0 ? "text-green-400" : "text-red-400"
                    }`}>
                      {isCalculating ? (
                        <span className="animate-pulse">Calculating...</span>
                      ) : (
                        `${profit >= 0 ? "+" : ""}$${Math.abs(profit).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}`
                      )}
                    </div>
                  </div>
                  <div className={`text-2xl font-bold ${
                    profit >= 0 ? "text-green-400" : "text-red-400"
                  }`}>
                    {percentageGain >= 0 ? "+" : ""}
                    {Math.abs(percentageGain).toFixed(2)}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ExpandableProfitCalculator