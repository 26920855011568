import React, { useState, useEffect } from 'react';
import { X, Gift } from 'lucide-react';
import { useAuth } from '../../context/AuthProvider';

const PromoModal = ({ onLoginClick }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { isAuthenticated } = useAuth();
  
  useEffect(() => {
    // Only show for non-authenticated users after 30 seconds
    if (!isAuthenticated) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 20000);
      
      return () => clearTimeout(timer);
    }
  }, [isAuthenticated]);

  if (!isVisible || isAuthenticated) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]">
      <div className="bg-white rounded-lg shadow-xl w-96 p-6 relative animate-fadeIn">
        <button 
          onClick={() => setIsVisible(false)} 
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>

        <div className="flex flex-col items-center space-y-6">
          <div className="bg-blue-100 p-4 rounded-full">
            <Gift size={48} className="text-blue-500" />
          </div>
          
          <h2 className="text-2xl font-bold text-center text-gray-800">
            Get Your Free Daily Tokens!
          </h2>
          
          <div className="text-center space-y-2">
            <p className="text-gray-600">
              Sign up now and receive <span className="font-bold text-blue-500">3 free tokens daily</span>
            </p>
            <p className="text-sm text-gray-500">
              Use tokens to access premium features and analysis
            </p>
          </div>
          
          <button
            onClick={() => {
              onLoginClick();
              setIsVisible(false);
            }}
            className="w-full py-3 bg-blue-500 text-white rounded-lg font-semibold hover:bg-blue-600 transform transition-all hover:scale-105"
          >
            Sign Up Now
          </button>
          
          <p className="text-xs text-gray-500">
            Join thousands of traders who have already signed up!
          </p>
        </div>
      </div>
    </div>
  );
};

export default PromoModal;