export const ROUTES = {
    HOME_PATH: '/home',
    SOTD_PATH: '/sotd',
    ABOUT_PATH: '/about',
    CONTACT_PATH: '/contact',
    PLANS_PATH:'/plans',
    PAYMENT_SUCCESS_PATH:'/payment/success',
    PAYMENT_FAILS_PATH:'/payment/cancel',
    RESET_PASSWORD_PATH:'/reset-password',
    VERIFY_EMAIL:'/verify-email',
};